import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  state() {
    return {
      // msalConfig: {
      //   auth: {
      //     clientId: 'c8b3d3d2-1a7c-45fa-b365-367729f083d1' ,
      //     authority: "https://verixa.b2clogin.com/verixa.onmicrosoft.com/B2C_1_signupsignin"   ,
      //     knownAuthorities: ['verixa.b2clogin.com'] ,
      //     postLogoutRedirectUri: 'http://localhost:8080'
      //   },
      //   cache: {
      //     cacheLocation: 'localStorage',
      //   },
      // },
      // accessToken: ''
      locationGUID: '',
      calendarObject: {},
      fc_scroll: 0,
    };
  },
  mutations: {
    // setAccessToken(state, token){
    //   state.accessToken = token;
    // }
  },
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule
  }
});

export default store;
