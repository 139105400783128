import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import MyDashboard from "../views/MyDashboard.vue";
import Users from "../components/settings/Users.vue";
import Cameras from "../components/settings/Cameras/Camera.vue";
import store from '../store/index';
import Profile from '@/components/Profile/Profile.vue';
import Error500 from "@/views/error/Error500.vue"
import Login from "@/views/Login.vue";
import { defineComponent } from "vue";
import { getInstance } from "@/auth/auth"
import { useAuth0 } from "@/auth/auth";
import { Actions } from "@/store/enums/StoreEnums";
import Incidents from "@/components/Reports/Incident.vue";
import Hangar from "@/components/Reports/Hangar.vue";
import HangarSpeed from "@/components/Reports/HangarSpeed.vue";
import CreateAlert from "@/components/Alerts/CreateAlert.vue";
import AlertsView from "@/components/Alerts/AlertsView.vue";

const authenticationGuard = (to, from, next) => {
  // console.log("guard checking to", to)
  // console.log("guard checking from", from)
  // console.log("guard checking next", next)
  // console.log("guard checking currentuser", store.getters.currentUser)

  if (to.name !== 'login' && !store.getters.currentUser) {
    next({ name: 'login', query: { redirectFrom: to.fullPath } })
  }
  else {
    next()
  }
};

const adminGuard = (to, from, next) => {
  const isAdmin = store.getters.currentUser.currentLocation.isAdmin
  if (isAdmin) {
    next();
  }
  else {
    return next(from);
  }
};

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    redirect: "/mydashboard",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: MyDashboard,
      },
      {
        path: "/mydashboard",
        name: "mydashboard",
        component: MyDashboard,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/settings/users",
        name: "UsersManagement",
        component: Users,
        beforeEnter: adminGuard
      },   
      {
        path: "/settings/cameras",
        name: "Cameras",
        component: Cameras,
        beforeEnter: adminGuard
      },
      {
        path: "/reports/incidents",
        name: "IncidentReports",
        component: Incidents,
      }, 
      {
        path: "/reports/hangar",
        name: "HangarReport",
        component: Hangar,
      }, 
      {
        path: "/reports/hangarSpeed",
        name: "HangarSpeedReport",
        component: HangarSpeed,
      }, 
      {
        path: "/alerts/new",
        name: "Alerts",
        component: CreateAlert,
      }, 
      {
        path: "/alerts",
        name: "AlertsView",
        component: AlertsView,
      } 
    ]

  },  
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },

  {
    path: "/logout",
    name: "logout",
    component: defineComponent({
      async mounted() {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        localStorage.removeItem("LocationUserToken");
        if (store.getters.currentUser) {
          await getInstance().logout({ returnTo: window.location.origin });
        } else {
          const domain = "overwatchhv.us.auth0.com";
          const clientId = "VDYsrjOKxDHChrIB8yaF7baqVVZb6zR8";
          const audience = "https://api.overwatch.ca";

          const auth0 = await useAuth0({
            domain,
            clientId,
            audience,
            redirectUri: `${window.location.origin}/login`,
            onRedirectCallback: (appState) => {
              console.log("appstate", appState);
              this.$router.push(
                appState && appState.targetUrl
                  ? appState.targetUrl
                  : window.location.pathname
              );
            },
          });
          await auth0.logout({ returnTo: window.location.origin });
        }
      }
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/500",
    name: "Internal Error",
    component: Error500,
  },



];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.currentUser) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router;
