<template>
	<div class="row">
		<div class="col-1" style="text-align: center">
			<span class="px-3 bg-grey"> {{id}} </span>
		</div>
		<div class="col-5">
			<p class="fs-4">{{name}}</p>
		</div>
		<div class="col-2">
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" :checked="status" @click="toggleAlert()"/>
				<label class="form-check-label" for="inlineCheckbox1">On/Off</label>
			</div>
		</div>
		<div class="col-4 text-end">
			<span @click="openEditAlertModal()" title="edit"><i class="fa-solid fa-pen customIcon"></i>&nbsp; &nbsp;</span> 
			<span @click="openDeleteAlertModal()" title="delete"><i class="fa-solid fa-trash customIcon"></i></span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			id: { type: Number, required: true },
			// count: { type: Number, required: true },
			name: { type: String, required: true },
			status: { type: Boolean, required: true },
		},
        methods:{
            openEditAlertModal(){
                this.$emit("openEditAlertModal", this.id);
            },
            openDeleteAlertModal(){
                this.$emit("openDeleteAlertModal", this.id);
            },
			toggleAlert(){
				this.$emit("toggleAlert", this.id);
			}
        },
		setup() {},
	};
</script>

<style lang="scss" scoped>
	.customIcon{
		font-size: 20px;
		cursor: pointer;
	}
	
	.bg-grey {
		background: grey;
	}
</style>
