<template>
	<div class="row gy-5 g-xl-8 mb-5">
		<div class="col-10">
			<h1>Good morning, {{ userFirstName }}.</h1>		
		</div>
		<div class="col-2">
			<!-- <button type="button" class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#violationModal">New Violation</button> -->
		</div>
	</div>

	<div class="card mb-5">
		<div class="d-flex py-2 align-items-center">
			<div class="d-flex align-items-center grey-bg py-2 px-7" style="width:40%">
				<div class="symbol symbol-50px me-5">
					<i
					class="fas fa-calendar-alt fs-30-px mx-2"
					style="color: #009ef7"
					></i>
				</div>
				<div class="flex-grow-1 text-start">
					<Datepicker v-model="dateRange" :multiCalendars="true" range id="date_range" :presetRanges="presetRanges" :format="rangeFormat" :enableTimePicker="false" @update:modelValue="getDashboardData()"/>
				</div>
			</div>

		</div>
	</div>

	<!-- Modal -->
	<div class="modal fade" id="violationModal" tabindex="-1" aria-labelledby="violationModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="violationModalLabel">Add New Violation</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<label class="form-check-label">Violation Type : </label>
					<select class="form-select" v-model="violationObj.ViolationTypeId">
						<option selected :value="0">Choose...</option>
						<option v-for="violationType in violationTypeList" :key="violationType.violationTypeId" :value="violationType.violationTypeId">{{ violationType.violationTypeName }}</option>
					</select>
					<br />
					<label class="form-check-label">Camera : </label>
					<select class="form-select" v-model="violationObj.CameraId">
						<option selected :value="0">Choose...</option>
						<option v-for="camera in cameraList" :key="camera.cameraId" :value="camera.cameraId">{{ camera.cameraName }}</option>
					</select>
					<br />
					<label class="form-check-label">Start Time :</label>
					<input class="form-control" type="datetime-local" id="startTime" v-model="violationObj.StartTime" />
					<br />
					<label class="form-check-label">End Time :</label>
					<input class="form-control" type="datetime-local" id="endTime" v-model="violationObj.EndTime" />
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					<button type="button" class="btn btn-primary" @click="addViolation()">Save changes</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-8">
      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-6 mb-5">
          <router-link :to="{path: '/reports/incidents' , query: { startTime : this.dateRange[0].toISOString() , endTime: this.dateRange[1].toISOString() } }" class="card-xl-stretch mb-xl-8 bg-white card h-100">
            <div class="card-body row m-0 w-100">
              <div style="text-align: center">
                <p class="fw-bolder needImprovments">{{ dashboardData.total }}</p>
                <p class="text-inverse-white fw-bold fs-5 m-0">Total Violations </p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6 mb-5 ">
          <router-link to="" class="card-xl-stretch mb-xl-8 bg-white card h-100">
            <div class="card-body row m-0">
              <div style="text-align: center">
                <i class="fas fa-info-circle customIcon" title="Number of days within date range without any violations"></i>
                <p class="fw-bolder perfectAppts">{{ dashboardData.safeDays }}</p>
                <p class="text-inverse-white fw-bold fs-5 m-0">Violation-free Days</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 my-5 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <h1 class="p-5">Violations per Month</h1>
              <apexchart width="100%" height="300px" type="bar" :options="chartOptions" :series="series" @dataPointSelection="abc()"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 my-5 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <h1 class="p-5">Violation Distribution</h1>
              <apexchart width="100%" height="300px" type="donut" :options="donutChartOptions" :series="donutSeries"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
      <div class="col-12">
        <div class="card w-100">
          <div class="card-body">
            <h1 class="p-5">Airport Activity</h1>
            <timeline :timeline-object="hangarReport"/>
          </div>
        </div>
      </div>
    </div>
	</div>
	<div class="row ">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="card w-100">
        <div class="card-body">
          <h1 class="p-5">Speed Average</h1>
          <SpeedAverage class="p-5" />
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 mb-5">
      <div class="card w-100">
        <div class="card-body">
          <h1 class="p-5">Camera Previews</h1>
          <MultipleCamPreview class="p-5" />
        </div>
      </div>
    </div>
<!--    <div class="col-12 col-sm-12 col-md-12 col-lg-12">-->
<!--      <div class="card w-100">-->
<!--        <div class="card-body">-->
<!--          <h1 class="p-5">Camera Previews</h1>-->
<!--          <CanvasDraw />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
	import { useStore } from "vuex";
	import * as bootstrap from "bootstrap";
	import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subDays, format } from "date-fns";
  import Timeline from "@/components/TimeLine/Timeline.vue";
  import SpeedAverage from "@/components/AreaChart/SpeedAverage.vue";
  import MultipleCamPreview from "@/components/Player/MultipleCamPreview.vue";
  import CanvasDraw from "@/components/CanvasDraw/CanvasDraw.vue";

  export default defineComponent({
		name: "kt-widget-9",
		components: {Timeline, SpeedAverage, MultipleCamPreview, CanvasDraw},
		props: {
			widgetClasses: String,
		},
		data: function () {
			return {
				// date range picker presets
        hangarReport: [],
				presetRanges: [
					{ label: "Today", range: [new Date(), new Date()] },
					{ label: "Yesterday", range: [subDays(new Date() , 1), subDays(new Date() , 1)] },
					{ label: "Last 7 Days", range: [subDays(new Date() , 7) , new Date()] },
					{ label: "Last 30 Days", range: [subDays(new Date() , 30) , new Date()] },
					{ label: "This month", range: [startOfMonth(new Date()), endOfMonth(new Date())] },
					{ label: "Last month", range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
					{ label: "This year", range: [startOfYear(new Date()), endOfYear(new Date())] },
				],
				// end daterange presets
				maxDate: new Date().toISOString().split("T")[0],
				userFirstName:null,
				dateRange: [startOfMonth(new Date()), endOfMonth(new Date())],
				dashboardData: {
					SafeDays: { type: Number, value: 0 },
					MTD: { type: Number, value: 0 },
					YTD: { type: Number, value: 0 },
					AdditionalData: [
						{
							ViolationCount: { type: Number, value: 0 },
							Month: { type: Number, value: 0 },
							ViolationSeverityName: { type: String, value: "" },
						},
					],
				},
				violationModal: null,
				violationTypeList: [],
				violationSeverityList: [],
				cameraList: [],
				violationObj: {
					ViolationTypeId: { type: Number, value: 0 },
					CameraId: { type: Number, value: 0 },
					StartTime: { type: Date, value: "" },
					EndTime: { type: Date, value: "" },
				},
				series: [],
				calculatedSeries: [],
				chartOptions: {
					chart: {
						type: "bar",
						stacked: true,
						toolbar: {
							show: true,
							offsetX: 0,
							offsetY: 0,
							tools: {
								download: true,
								selection: true,
								zoom: true,
								zoomin: true,
								zoomout: true,
								pan: true,
								reset: true,
							},
						},
						zoom: {
							enabled: true,
						},
						events: {
							dataPointSelection: (event, chartContext, config ) => {
								let month = config.w.config.xaxis.categories[config.dataPointIndex];
								let monthNb = 0;

								switch (month) {
									case "Jan":
										monthNb = 0;
										break;
									case "Feb":
										monthNb = 1;
										break;
									case "Mar":
										monthNb = 2;
										break;
									case "Apr":
										monthNb = 3;
										break;
									case "May":
										monthNb = 4;
										break;
									case "Jun":
										monthNb = 5;
										break;
									case "Jul":
										monthNb = 6;
										break;
									case "Aug":
										monthNb = 7;
										break;
									case "Sep":
										monthNb = 8;
										break;
									case "Oct":
										monthNb = 9;
										break;
									case "Nov":
										monthNb = 10;
										break;
									case "Dec":
										monthNb = 11;
										break;
									default:
										break;
								}
								
								this.navigateToBarMonth(monthNb);
							}
						}
					},
					dataLabels: {
						enabled: true,
					},
					noData: {
						text: "No data available for selected range",
						align: "center",
						verticalAlign: "middle",
						offsetX: 0,
						offsetY: 0,
						style: {
							color: "black",
							fontSize: "14px",
							fontFamily: undefined,
						},
					},					
					xaxis: {			
						categories: ['Mar', 'Apr', 'May', 'June'],			
						labels: {
							show: true
						}
					}
				},
				donutSeries: [],
				donutChartOptions: {
					labels: [],
					colors: [],
					chart: {
						type: "donut",
					},
					responsive: [
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
			};
		},
		methods: {
      async getHangarReport1Week() {
        let today = new Date();
        let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()-7);

        let body = {
          startTime: lastWeek.toISOString(),
          endTime: today.toISOString(),
        };
        let dates = [];

        let searchRes = await ApiService.postData("Violation/CameraAnalysis", body, {});
        if (searchRes.status == 200) {
          let report = searchRes.data.detectedEvents;
          for (let i = 0 ; i < report.length; i++){
            let eltDate = report[i].time;

            // let dayMonth = eltDate.split("T")[0];
            let dayMonth = format(new Date(eltDate), "y-M-d");
            if(!dates.find(x=> x && x.date === dayMonth)){
              dates.push({date: dayMonth, data: [report[i]]})
            }else{
              dates.find(x=> x && x.date === dayMonth).data.push(report[i])
            }
          }
          this.hangarReport = dates;
        }
      },
			rangeFormat(range){
				return `${format(range[0], "PPPP")} - ${format(range[1], "PPPP")}`;
			},
			async getViolationTypes() {
				let res = await ApiService.get("ViolationType");

				if (res) {
					this.violationTypeList = res.data;
				}
			},
			async getViolationSeverities() {
				let res = await ApiService.get("ViolationSeverity");

				if (res) {
					this.violationSeverityList = res.data;
				}
			},
			async getCameras() {
				let res = await ApiService.get("Camera");

				if (res) {
					this.cameraList = res.data;
				}
			},
			async addViolation() {
				if (this.violationObj.ViolationTypeId.value == 0 || this.violationObj.ViolationTypeId == 0 || this.violationObj.CameraId.value == 0 || this.violationObj.CameraId == 0 || this.violationObj.StartTime.value == "" || this.violationObj.EndTime.value == "") {
					alert("All Fields Are Mandatory !!");
				} else {
					let res = await ApiService.post("Violation", this.violationObj);

					if (res) {
						this.violationModal.hide();
						this.getDashboardData();
					}
				}
			},
			prepareDonutChartData(violations){
				if(this.donutSeries.length > 0){
					this.donutSeries = [];
					this.donutChartOptions.labels = [];
					this.donutChartOptions.colors = [];
				}

				violations.forEach(vio=>{
					this.donutSeries.push(vio.count);
					this.donutChartOptions.labels.push(vio.severity);

					let color = this.violationSeverityList.length > 0 ? this.violationSeverityList.find(x=> x.violationSeverityName === vio.severity).violationSeverityColor : '';
					if(color != '')
					this.donutChartOptions.colors.push(color);

				});
			},
			async getDashboardData() {
				let res = await ApiService.get("Dashboard/GetDashboard?startDate=" + this.dateRange[0].toISOString().slice(0, 10) + "&endDate=" + this.dateRange[1].toISOString().slice(0, 10));

				if (res) {
					this.dashboardData = res.data;
					if (this.dashboardData.additionalData) {
						this.LoadData(this.dashboardData.additionalData);
					}
					if(this.dashboardData.violationsBySeverity){
						this.prepareDonutChartData(this.dashboardData.violationsBySeverity)
					}
				}
			},
			async LoadData(data) {
				// console.log(data)
				let finalSeriesData = [
					// {name: "Low", data: [], color: '#009EF7'}, //						colors: [, , ]					
					// {name: "Medium", data: [], color: '#FF9100'},
					// {name: "High", data: [], color: '#FF0000'}
				];
				let finalCategories = [];

				data.forEach((element) => {
					let label = "";

					switch (element.month) {
						case 1:
							label = "Jan";
							break;
						case 2:
							label = "Feb";
							break;
						case 3:
							label = "Mar";
							break;
						case 4:
							label = "Apr";
							break;
						case 5:
							label = "May";
							break;
						case 6:
							label = "Jun";
							break;
						case 7:
							label = "Jul";
							break;
						case 8:
							label = "Aug";
							break;
						case 9:
							label = "Sep";
							break;
						case 10:
							label = "Oct";
							break;
						case 11:
							label = "Nov";
							break;
						case 12:
							label = "Dec";
							break;
						default:
							break;
					}
					if(!finalCategories.find(x=> x == label)){
						finalCategories.push(label)
					}
					let violationByNameFound = finalSeriesData.find(x=> x.name === element.violationSeverityName);
					if(!violationByNameFound){
						let color = this.violationSeverityList.length > 0 ? this.violationSeverityList.find(x=> x.violationSeverityName === element.violationSeverityName).violationSeverityColor : '';
						color != '' ? finalSeriesData.push({name: element.violationSeverityName , color: color ,  data: []}) : finalSeriesData.push({name: element.violationSeverityName ,  data: []})
					}
					if(!finalSeriesData.find(x=> x.name === element.violationSeverityName)){
						throw "something wrong happened please call support";
					}
					finalSeriesData.find(x=> x.name === element.violationSeverityName).data.push(element.violationCount);
				});
				this.chartOptions
				// console.log(finalSeriesData);
				this.series = finalSeriesData;

				this.chartOptions = {
					...this.chartOptions,
					...{
						xaxis: {
							categories: finalCategories,
						},
					},
				};
			},
			navigateToBarMonth(month){
				console.log(month)
				const startTime = new Date(this.dateRange[0].getFullYear() , month , 1) ;
				const endTime = new Date(this.dateRange[0].getFullYear() , month + 1, 0);
				this.$router.push({path: '/reports/incidents' , query: { startTime : startTime.toISOString() , endTime: endTime.toISOString() } })
			}
		},
		async mounted() {
			const store = useStore();
			const locationInfo = store.getters.currentLocationInfo;
			const currentUser = store.getters.currentUser;
			if (locationInfo) {
				this.locationGoal = locationInfo.goalHourlyProduction;
			}

			if (currentUser) {
				this.userFirstName = currentUser.firstName;
			}

			setCurrentPageTitle("Dashboard");

			var modalElement = document.getElementById("violationModal");
			this.violationModal = new bootstrap.Modal(modalElement);
      this.getHangarReport1Week();
     this.getViolationTypes();
     this.getViolationSeverities();
     this.getCameras();
     this.getDashboardData();
		},
	});
</script>

<style lang="scss" scoped>
	.redclass {
		background-color: red;
		border-radius: 0.475rem;
	}

	.greenclass {
		background-color: #50cd89;
		border-radius: 0.475rem;
	}

	.customSvg {
		text-align: center;
		padding-top: 5px;
	}

	.forcasted {
		font-size: 40px;
		color: white;
		margin: 0px;
		display: inline;
	}

	.forcastedText {
		color: white;
	}

	.perfectAppts {
		font-size: 40px;
		color: #50cd25;
		margin: 0px;
	}

	.needImprovments {
		font-size: 40px;
		color: #ff9100;
		margin: 0px;
	}

	.redBadge {
		font-size: 18px;
		color: red;
		background-color: white;
		padding: 5px;
		vertical-align: super;
	}

	.greenBadge {
		font-size: 18px;
		color: #50cd89;
		background-color: white;
		padding: 5px;
		vertical-align: super;
	}

	.card-body {
		padding: 1px !important;
	}

	.customIcon {
		float: right;
		padding-top: 5px;
		font-size: 16px;
	}

</style>