
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import { mapMutations } from "vuex";
import { useAuth0 } from "./auth";
import ApiService from "@/core/services/ApiService";
import Keypad from "@/components/Keypad.vue";


import KTLoader from "@/components/Loader.vue";
import * as bootstrap from "bootstrap";

export default defineComponent({
  name: "app",
  components: { KTLoader },
  data() {
    return {
      asideEnabled: asideEnabled,
      themeLightLogo: themeLightLogo,
      themeDarkLogo: themeDarkLogo,
      loaderLogo: loaderLogo,
      modal: null,
      showKeypad: false,
    };
  },
  methods: {
    ...mapMutations(["setAccessToken"]),
    async beforeCreate() {
      //need to externalize
      console.log("before create");

      console.log("setting signedin = true");
      //this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    },
  },
  setup() {
    const store = useStore();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    // show page loading
    //store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    return {
      pageTitle: pageTitle,
      contentWidthFluid,
      loaderEnabled,
    };
  },
});
