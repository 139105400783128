<template>
	<!-- start add user modal -->
	<div class="modal fade" tabindex="-1" id="user_modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Add user for ${currentLocationName}` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<edit-user v-if="showCreateEditUser && users" :selectedUser="null" :initialUser="initialUser" :users="users" @closeAddUserForm="closeAddUserForm" />
			</div>
		</div>
	</div>
	<!-- end modal -->

	<div :class="`card mb-5`" style="width: 100%; height: 100%">
		<!--begin::Header-->
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label fw-bolder fs-3 mb-1">Users</span>
			</h3>

			<div class="card-toolbar mr-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a new user">
				<a @click="newUser()" class="btn btn-sm btn-light-primary add-user button-font-shadow shadow">
					<span class="svg-icon svg-icon-3">
						<inline-svg src="/media/icons/duotone/Communication/Add-user.svg" />
					</span>
					New User
				</a>
			</div>
		</div>
		<!--end::Header-->

		<!--begin::Body-->
		<div class="card-body py-3 row">
			<!--begin::Table container-->
			<div class="table-responsive row">
				<!--begin::Table-->
				<div class="row">
					<ag-grid-vue :defaultColDef="defaultColDef" :columnDefs="columnDefs" :rowData="rowData" :rowHeight="35" @grid-ready="onGridReady" @gridSizeChanged="gridSizeChanged" style="width: 100%; height: 100%" class="ag-theme-balham"> </ag-grid-vue>
				</div>
				<!--end::Table-->
			</div>
			<!--end::Table container-->
		</div>
		<!--begin::Body-->
	</div>

	<!-- start edit user modal -->
	<div class="modal fade" tabindex="-1" id="editUser_modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Edit user for ${currentLocationName}` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<edit-user v-if="editUserVisible && users" :selectedUser="editUserModalObject" :initialUser="initialUser" :users="users" @closeEditUserForm="closeEditUserForm" />
			</div>
		</div>
	</div>
	<!-- end modal -->

	<!-- start delete user confirmation -->
	<div class="modal fade" tabindex="-1" id="deleteUser_modal" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" v-if="userToDelete">Delete User {{ userToDelete.firstName }} {{ userToDelete.lastName }}</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<div class="modal-body">
					<p v-if="userToDelete">Are you sure you want to delete user {{ userToDelete.firstName }} {{ userToDelete.lastName }} ?</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
					<button v-if="userToDelete" type="button" class="btn btn-danger" @click="deleteUser(userToDelete.userId)">Delete</button>
				</div>
			</div>
		</div>
	</div>
	<!-- end delete user confirmation -->

	<!-- start change password modal -->
	<div class="modal fade" tabindex="-1" id="password_modal">
		<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
			<h5 class="modal-title">
				{{ `Change Password` }}
			</h5>

			<!--begin::Close-->
			<div
				class="btn btn-icon btn-sm btn-active-light-primary ms-2"
				data-bs-dismiss="modal"
				aria-label="Close"
			>
				<span class="svg-icon svg-icon-2x"></span>
				<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
			</div>
			<!--end::Close-->
			</div>
			<AddEditPassword
			v-if="editUserModalObject"
			:selectedUser="editUserModalObject"
			@closePasswordModal="closePasswordModal"
			/>
		</div>
		</div>
	</div>
	<!-- end change password modal -->
</template>

<script>
	import { AgGridVue } from "ag-grid-vue3";
	import { defineComponent, ref } from "vue";
	import ApiService from "@/core/services/ApiService";
	import * as bootstrap from "bootstrap";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import EditUser from "@/components/settings/EditUser.vue";
	import { useStore } from "vuex";
	import UserManagmentBtnCellRenderer from "./UserManagmentBtnCellRenderer.vue";
	import AddEditPassword from "@/components/settings/AddEditPassword.vue";

	export default defineComponent({
		name: "kt-widget-9",
		components: {
			AgGridVue,
			EditUser,
			AddEditPassword,
			UserManagmentBtnCellRenderer,
		},
		props: {
			widgetClasses: String,
		},
		data() {
			return {
				list: [],
				modal: null,
				editUserModal: null,
				passwordModal: null,
				showCreateEditUser: false,
				editUserVisible: false,
				editUserModalObject: null,

				//ag-grid
				defaultColDef: null,
				columnDefs: null,
				rowData: null,
				gridApi: null,
				columnApi: null,
				api: null,

				userToDelete: null,
				deleteModal: null,
				users: null,
			};
		},
		async beforeMount() {
			this.refreshTable();
		},

		async mounted() {

			this.loadData();
			var modalElement = document.getElementById("user_modal");
			this.modal = new bootstrap.Modal(modalElement);
			if (modalElement) {
				var self = this;
				modalElement.addEventListener("hidden.bs.modal", function (event) {
					self.showCreateEditUser = false;
				});
			}

			// editUser_modal
			var modalElement = document.getElementById("editUser_modal");
			this.editUserModal = new bootstrap.Modal(modalElement);
			if (modalElement) {
				var self = this;
				modalElement.addEventListener("hidden.bs.modal", function (event) {
					self.editUserModalObject = null;
					self.editUserVisible = false;
				});
			}
		},
		methods: {
			async refreshTable() {
				this.defaultColDef = {
					resizable: true,
					sortable: true,
					filter: true,
					floatingFilter: true,
					suppressMenu: true,
					filterParams: {
						buttons: ["apply", "reset"],
						closeOnApply: true,
						suppressAndOrCondition: true,
					},
				};

				let self = this;
				this.columnDefs = [
					{ headerName: "ID", field: "userId", filter: true },
					{ headerName: "First Name", field: "firstName", filter: true },
					{ headerName: "Last Name", field: "lastName", filter: true },
					{ headerName: "is admin", field: "isAdmin", filter: true },
					{ headerName: "Email Address", field: "email", filter: true },

					{
						field: "edit",
						headerName: "Actions",
						cellRenderer : UserManagmentBtnCellRenderer,
						resizable: false,
						filter: false,
						maxWidth: 187,
						cellRendererParams: {
							clicked: function (selectedData) {
								if (self.showCreateEditUser) {
									self.showCreateEditUser = !self.showCreateEditUser;
								}
								self.editUserModalObject = null;
								self.changeSelectedUser(selectedData);
								self.editUserVisible = true;
							},
							keyClicked: function (selectedData) {
								self.editPassword(selectedData);
							},
							deleteClicked: function (selectedData) {
								self.confirmUserDeletion(selectedData);
							},
						},
					},
				];

								let usersList = await ApiService.get("User");
				usersList = usersList.data;
				for (let i = 0; i < usersList.length; i++) {
					const element = usersList[i];
					usersList[i].edit = element;
				}

				this.users = usersList;
				
				this.rowData = usersList;
				this.api.sizeColumnsToFit();

			},
			closeAddUserForm() {
				this.refreshTable();
				this.modal.hide();
				this.showCreateEditUser = false;
			},
			closeEditUserForm() {
				this.refreshTable();
				this.editUserVisible = false;
				this.editUserModal.hide();
			},
			onGridReady(params) {
				this.api = params.api;
				this.gridApi = params.api;
				this.columnApi = params.columnApi;
			},
			gridSizeChanged() {
				this.api.sizeColumnsToFit();
			},
			getSelectedRows() {
				try {
					if (this.showCreateEditUser) {
						this.showCreateEditUser = !this.showCreateEditUser;
					}
					const selectedNodes = this.gridApi.getSelectedNodes();
					const selectedData = selectedNodes.map((node) => node.data);
					if (!selectedNodes[0]) {
						throw "Select a user first.";
					}
					this.editUserModalObject = selectedData[0];
					this.editUserVisible = true;
					this.editUserModal.show();
				} catch (err) {}
			},
			newUser() {
				this.modal.show();
				this.showCreateEditUser = true;
			},
			async loadData() {
				let list = (await ApiService.get("user")).data;
				this.list = list;
			},
			onSubmitCompleted() {
				this.loadData();

				this.modal.hide();
			},
			confirmUserDeletion(data) {
				this.userToDelete = data;
				let modalElement = document.getElementById("deleteUser_modal");
				if (modalElement) {
					modalElement.addEventListener("hidden.bs.modal", function (event) {});
				}
				this.deleteModal = new bootstrap.Modal(modalElement);
				this.deleteModal.show();
			},
			async deleteUser(id) {
				let res = await ApiService.delete(`/User/${id}`);
				if (res.status === 200) {
					this.refreshTable();
					this.deleteModal.hide();
				} else {
					alert("Something wen wrong while deleting user.");
				}
			},
			changeSelectedUser(user) {
				this.editUserModal.show();
				this.editUserModalObject = user;
			},
			editPassword(userData) {
				this.editUserModalObject = userData;
				var modalElement = document.getElementById("password_modal");
				this.passwordModal = new bootstrap.Modal(modalElement);
				this.passwordModal.show();
			},
			closePasswordModal() {
				this.refreshTable();
				this.passwordModal.hide();
			},
		},
		setup() {
			setCurrentPageTitle("User Management");
			const checked = ref(false);
			const store = useStore();
			const initialUser = store.getters.currentUser;
			const currentLocationName = initialUser.currentLocation.name;

			return {
				checked,
				initialUser,
				currentLocationName,
			};
		},
	});
</script>

<style lang="scss" scoped>
	.mr-3 {
		margin-right: 3rem !important;
	}

	@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
	@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
