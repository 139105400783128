
import { defineComponent, reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required, helpers, minValue, requiredIf } from "@vuelidate/validators";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import { AxiosResponse } from "axios";

interface FormState {
  providerId: string;
  firstName: string;
  lastName: string;
  goalWage: number;
  baseWage: number;
}

export default defineComponent({
  data(){return {}},
  props: {
    save: {
      type: Boolean,
      required: true,
    }
  },
    watch: {
    save(value, oldValue) {
      if(value === true){
        this.submit()
      }
    }
    },

  methods: {
    async submit() {
      if(this.v$){
        this.v$.$validate();
        if (!this.v$.$error) {
          try {
            if(!this.providerInfo){
              return;
            }
            const payload:any = {
              providerId: this.state.providerId,
              notes: this.providerInfo.notes,
              excludeFromAnalysis: this.providerInfo.excludeFromAnalysis,
              goalWage: this.state.goalWage,
              baseWage: this.providerInfo.baseWage,
              excludeFromCalendar: this.providerInfo.excludeFromCalendar,
              userId: this.providerInfo.userId,
            }
            console.log(payload)
            let res: AxiosResponse = await ApiService.postData("/Provider", payload, {});
            this.$emit("closeEdit");
          } catch (ex) {
            console.log(ex);
          }
        }
      }

    },
  },
  async setup(props, context) {
    let providerInfo: Models.Provider= await (await ApiService.get("/Provider/UserProviders")).data[0];
    if(!providerInfo){
      context.emit("failed")
      return {
        providerInfo
      }
    }
    let state: FormState = reactive({
      providerId: providerInfo.provider_id,
      firstName: providerInfo.first_name,
      lastName: providerInfo.last_name,
      goalWage: providerInfo.goalWage, //changeable
      baseWage: providerInfo.baseWage,
    });

    const rules: any = computed(() => {
      return {
        goalWage: {
          minValue: helpers.withMessage("Goal wage can't be less than base wage.", minValue(state.baseWage)),
        }
      };
    });
    const v$ = useValidate(rules, state);

    const closeEdit = () => {
      context.emit("closeEdit");
    };
    return {
      closeEdit,
      state,
      v$,
      providerInfo,
    };
  },
});
