
	import { defineComponent, reactive, computed } from "vue";
	import useValidate from "@vuelidate/core";
	import { required, helpers } from "@vuelidate/validators";
	import ApiService from "@/core/services/ApiService";
	import { Models } from "@/Models/Models";

	interface FormState {
		firstName: string;
		lastName: string;
		// goalWage: number;
		// position: string;
	}

	export default defineComponent({
		props: {
			initialUser: {
				type: Object,
				required: true,
			},
			colors: {
				required: true,
			},
			save: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				pickedColor: "",
        performanceDisplay: "",
			};
		},
		created() {
      console.log(this.initialUser)
			if (this.initialUser.preferences) {
        let pref = JSON.parse(this.initialUser.preferences);
				let color = pref.avatarColor;
				this.pickedColor = color;
				this.$emit("changeColor", color);
        this.performanceDisplay =  pref.performanceDisplay ? pref.performanceDisplay : 'scores' ;
        console.log("prefdisp", this.performanceDisplay)
			}
		},
		watch: {
			save(value, oldValue) {
				if (value === true) {
					this.submit();
				}
			},
		},

		methods: {
			async submit() {
				this.v$.$validate();
				if (!this.v$.$error) {
					try {
						const user = await ApiService.get(`User/current`);
						let currentUser = user.data as Models.CurrentUser;
						if (!currentUser.preferences || currentUser.preferences == "") {
							currentUser.preferences = JSON.stringify({});
						}
						let newPrefs = JSON.parse(currentUser.preferences);
						newPrefs.avatarColor = this.pickedColor;
            newPrefs.performanceDisplay = this.performanceDisplay;
						let payload: any = {
							firstName: this.state.firstName,
							lastName: this.state.lastName,
							// goalWage: this.state.goalWage,
							// position: this.state.position,
							preferences: JSON.stringify(newPrefs),
						};
						console.log(payload);
						if (!payload) {
							return;
						} else {
							const response = await ApiService.postData("/User/profile", payload, {});
							if (response.status === 200) {
								window.location.reload();
							} else {
								console.log(response);
							}
						}
					} catch (ex) {
						console.log(ex);
					}
				}
			},
			selectColor(color) {
				this.pickedColor = color;
				this.$emit("changeColor", color);
				console.log(color);
			},
		},
		setup(props, context) {
			let state: FormState = reactive({
				firstName: props.initialUser.firstName,
				lastName: props.initialUser.lastName,
				// goalWage: props.initialUser.goalWage,
				// position: props.initialUser.position,
			});

			const rules: any = computed(() => {
				return {
					firstName: {
						required: helpers.withMessage("First name is required.", required),
					},
					lastName: {
						required: helpers.withMessage("Last name is required.", required),
					},
				};
			});
			const v$ = useValidate(rules, state);

			const closeEdit = () => {
				context.emit("closeEdit");
			};
			return {
				closeEdit,
				state,
				v$,
			};
		},
	});
