<template>
	<div class="row mb-5">
		<h5>Search</h5>
		<div class="card">
			<div class="card-body">
				<div class="form-group mb-5">
					<label for="date_range">Date Range</label>
					<Datepicker v-model="dateRange" :multiCalendars="true" range id="date_range" :presetRanges="presetRanges" :format="rangeFormat" :enableTimePicker="false" />
				</div>
				<div class="form-group mb-5">
					<label for="incident_type">Incident Type</label>
					<Multiselect v-model="multiselectIncidentType.value" mode="tags" class="cornered-radius" ref="multiselect_inc_type" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectIncidentType.options" />
					<p v-if="multiselectIncidentType.value == null || multiselectIncidentType.value == ''" class="text-danger">Incident Type is required !</p>
				</div>

				<div class="form-group mb-5">
					<label for="incident_severity">Incident Severity</label>
					<Multiselect v-model="multiselectIncidentSeverity.value" mode="tags" class="cornered-radius" ref="multiselect_inc_sev" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectIncidentSeverity.options" />
				</div>
				<div class="row">
					<div class="col text-end">
						<button class="btn btn-primary" @click="search">Search</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<h5>Data Report</h5>
		<div class="card">
			<div class="card-body">
				<div class="row my-2">
					<div class="col">
						<span class="badge badge-success p-2">Low: {{ violationSeverityCounts.low }}</span>
					</div>
					<div class="col">
						<span class="badge badge-warning p-2">Moderate: {{ violationSeverityCounts.medium }} </span>
					</div>
					<div class="col">
						<span class="badge badge-danger p-2">High: {{ violationSeverityCounts.high }}</span>
					</div>
					<div class="col text-end">
						<button title="download as excel" class="btn btn-icon btn-active-light-primary" @click="downloadCSVFile('Incidents.csv')"><i class="fa-regular fa-circle-down fs-20-px"></i></button>
					</div>
				</div>
				<div class="row table-responsive h-100">
					<div class="" style="height: calc(100vh - 60vh)">
						<ag-grid-vue :defaultColDef="defaultColDef" :columnDefs="columnDefs" :rowData="rowData" :rowHeight="35" @grid-ready="onGridReady" @gridSizeChanged="gridSizeChanged" style="width: 100%; height: 100%" class="ag-theme-balham"> </ag-grid-vue>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- start watch violation modal -->
	<div class="modal fade" tabindex="-1" id="watchVideo_modal" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Watch Violation` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="closePlayer">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<div class="modal-body h-100">
					<div class="row">
						<div v-if="images && blobImages && images.length > 0 && images.length != blobImages.length" class="col-12 text-center" style="min-height: 340px !important">
							<div class="loader"></div>
							<p>{{ blobImages.length }}/{{ images.length }}</p>
						</div>
						<div class="col-12" v-else-if="images && images.length == 0">
							<br />
							<p class="text-center" v-if="isfetchingDone">No Images Found !!!</p>
						</div>
						<div class="col-12" v-else>
							<CustomImageRoller :blobImages="blobImages" ref="customImgRoller" v-if="blobImages.length > 0" :allowedDates="incidentAllowedDates" :datePickerActive="false" :startFromEnd="false"/>
						</div>
					</div>
					<!-- <div class="row">
						<div class="col-6">
							<button v-if="!images.length > 0" @click="fetchImages" class="btn btn-success">Fetch Images</button>
						</div>
						<div class="col-6">
							<button @click="RollImages" v-if="playerIntervalId == null && blobImages.length > 0" class="btn btn-success" style="float: right">play</button>
							<button @click="closePlayer" v-if="playerIntervalId && blobImages.length > 0" class="btn btn-danger" style="float: right">stop</button>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<!-- end modal -->

  <!-- start delete violation modal -->
  <div class="modal fade" tabindex="-1" id="deleteViolation_modal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Delete Violation` }}
          </h5>

          <!--begin::Close-->
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="closePlayer">
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body h-100">
          <div class="row">
            <p>Are you sure you want to delete violation {{incidentToDelete}} </p>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          <button class="btn btn-danger" @click="deleteIncident(incidentToDelete)">Confirm</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal -->
</template>

<script>
	import CustomImageRoller from "@/components/Player/CustomImageRoller.vue";
	import Multiselect from "@vueform/multiselect/dist/multiselect";
	import { AgGridVue } from "ag-grid-vue3";
	import { defineComponent } from "vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
  import {
    endOfMonth,
    endOfYear,
    format,
    startOfMonth,
    startOfYear,
    subMonths,
    subDays,
    parseISO,
    intervalToDuration
  } from "date-fns";
	import InlineSvg from "vue-inline-svg";
	import WatchVideoCellRenderer from "@/components/settings/AgGridCells/WatchVideoCellRenderer.vue";
  import WatchDeleteCellRenderer from "@/components/settings/AgGridCells/WatchDeleteCellRenderer.vue";
	import * as bootstrap from "bootstrap";
	import axios from "axios";
	var Buffer = require("buffer/").Buffer;

	export default defineComponent({
		components: { Multiselect, AgGridVue, InlineSvg, CustomImageRoller },
		data() {
			return {
				playerCamId: null,
				playerStartTime: null,
				playerSpeedfps: 1,
				playerEndTime: null,
				playerIntervalId: null,
				watchVideoModal: null,
				multiselectIncidentType: { value: [], options: [] },
				multiselectIncidentSeverity: { value: [], options: [] },
				dateRange: [startOfYear(new Date()), endOfYear(new Date())],
				violationSeverityCounts: { low: 0, medium: 0, high: 0 },
				images: [],
				blobImages: [],
				isfetchingDone: true,
				isLoading: false,
				currentBlobImageIndex: 0,
				incidentAllowedDates: [],

        //delete incident

        incidentToDelete: null,
        deleteIncidentModal: null,
        //end delete incident

				// date range picker presets
				presetRanges: [
					{ label: "Today", range: [new Date(), new Date()] },
					{ label: "Yesterday", range: [subDays(new Date(), 1), subDays(new Date(), 1)] },
					{ label: "Last 7 Days", range: [subDays(new Date(), 7), new Date()] },
					{ label: "Last 30 Days", range: [subDays(new Date(), 30), new Date()] },
					{ label: "This month", range: [startOfMonth(new Date()), endOfMonth(new Date())] },
					{ label: "Last month", range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
					{ label: "This year", range: [startOfYear(new Date()), endOfYear(new Date())] },
				],

				//ag-grid
				defaultColDef: null,
				columnDefs: null,
				rowData: null,
				gridApi: null,
				columnApi: null,
				api: null,
			};
		},
		methods: {
      async deleteIncident(id){
        let res = await ApiService.delete(`Violation/${id}`);
        if(res.status === 200){
          this.deleteIncidentModal.hide();
          await this.defaultSearch(
              this.dateRange[0],
              this.dateRange[1],
              this.multiselectIncidentType.options.map((x) => {
                return x.value;
              }),
              this.multiselectIncidentSeverity.options.map((x) => {
                return x.value;
              })
          );
          this.api.sizeColumnsToFit();
        }
      },
			closePlayer() {
				// clearInterval(this.playerIntervalId);
				// this.playerIntervalId = null;
				if(this.$refs.customImgRoller){
					this.$refs.customImgRoller.closePlayer();

				}
			},

			async fetchImages() {
				let days = [];
				let token = await ApiService.get("Stream/SAS");
				token = token.data.split("?")[1];
				let self = this;
				let payload = { violationId: this.violationId };
        console.log(payload)
				await ApiService.post("Violation/IncidentImages", payload).then((response) => {
					if (response) {
						this.images = response.data;
					}
				});

				if (this.images.length > 0) {
					for (let j = 0; j < this.images.length; j++) {
						const imgURL = `${this.images[j].imageUrl}?${token}`;
						let day = new Date(this.images[j].fileTimestamp).toString().split(" ");
						day = `${day[2]} ${day[1]} ${day[3]}`;
						if(!days.find(x=> x.toLowerCase() == day.toLowerCase())){
							days.push(day)
						}
            this.blobImages.push({ url: imgURL, time: new Date(this.images[j].fileTimestamp) })
					}
					this.incidentAllowedDates = days;
				} else {
					this.isfetchingDone = true;
				}
			},
			// async RollImages() {
			// 	let self = this;

			// 	if (this.blobImages.length > 0) {
			// 		let i = 0;
			// 		this.playerIntervalId = setInterval(() => {
			// 			self.$refs.imgRoller.src = this.blobImages[i];
			// 			i++;
			// 			if (i == this.blobImages.length - 1) {
			// 				i = 0;
			// 			}
			// 		}, this.playerSpeedfps * 100);
			// 	} else {
			// 		this.isfetchingDone = true;
			// 	}
			// },
			createCustomCsv() {
				const csvString = [["Violation Id", "Start Time", "Violation Type Name", "Violation Severity Name", "Camera Name"], ...this.rowData.map((item) => [item.violationId, item.startTime, item.violationTypeName, item.violationSeverityName, item.cameraName])].map((e) => e.join(",")).join("\n");

				return csvString;
			},
			downloadCSVFile(filename) {
				let csv = this.createCustomCsv();

				var csv_file, download_link;

				csv_file = new Blob([csv], { type: "text/csv" });

				download_link = document.createElement("a");

				download_link.download = filename;

				download_link.href = window.URL.createObjectURL(csv_file);

				download_link.style.display = "none";

				document.body.appendChild(download_link);

				download_link.click();
			},
			rangeFormat(range) {
				return `${format(range[0], "PPPP")} - ${format(range[1], "PPPP")}`;
			},
			async search() {
				if (!this.multiselectIncidentType.value == null || !this.multiselectIncidentType.value == "") {
					let body = {
						startTime: this.dateRange[0].toISOString(),
						endTime: this.dateRange[1].toISOString(),
						violationTypeIds: this.multiselectIncidentType.value,
						ViolationTypeSeverityIds: this.multiselectIncidentSeverity.value == null || this.multiselectIncidentSeverity.value == "" ? null : this.multiselectIncidentSeverity.value,
					};
					// console.log(body);
					let searchRes = await ApiService.postData("Report", body, {});
					if (searchRes.status == 200) {
						// initialize the counts
						this.violationSeverityCounts = { low: 0, medium: 0, high: 0 };
						// console.log(searchRes.data);
						searchRes.data.forEach((d) => {
							switch (d.violationSeverityName) {
								case "Low":
									this.violationSeverityCounts.low++;
									break;
								case "Medium":
									this.violationSeverityCounts.medium++;
									break;
								case "High":
									this.violationSeverityCounts.high++;
									break;

								default:
									break;
							}
						});
						this.refreshTable(searchRes.data);
						this.api.sizeColumnsToFit();
					}
				}
			},
			async getFormData() {
				let incidentTypes = await ApiService.get("ViolationType");
				let incidentsSevsRes = await ApiService.get("ViolationSeverity");
				if (incidentsSevsRes.status == 200 && incidentTypes.status == 200) {
					incidentsSevsRes.data.forEach((inc) => {
						this.multiselectIncidentSeverity.options.push({ value: inc.violationSeverityId, label: inc.violationSeverityName, color: inc.violationSeverityColor });
					});
					incidentTypes.data.forEach((inc) => {
						this.multiselectIncidentType.options.push({ value: inc.violationTypeId, label: inc.violationTypeName });
					});
				}
			},
			async defaultSearch(startDate, endDate, violationTypeIds, violationTypeSeverityIds) {
				// this.multiselectIncidentType.options.map(x=>{return x.value})
				// this.multiselectIncidentSeverity.options.map(x=>{return x.value})
				let body = {
					startTime: startDate.toISOString(),
					endTime: endDate.toISOString(),
					violationTypeIds: violationTypeIds,
					ViolationTypeSeverityIds: violationTypeSeverityIds,
				};
				let searchRes = await ApiService.postData("Report", body, {});
				this.refreshTable(searchRes.data);
				searchRes.data.forEach((d) => {
					switch (d.violationSeverityName) {
						case "Low":
							this.violationSeverityCounts.low++;
							break;
						case "Medium":
							this.violationSeverityCounts.medium++;
							break;
						case "High":
							this.violationSeverityCounts.high++;
							break;

						default:
							break;
					}
				});
				this.api.sizeColumnsToFit();
			},
			onGridReady(params) {
				this.api = params.api;
				this.gridApi = params.api;
				this.columnApi = params.columnApi;
			},
			gridSizeChanged() {
				this.api.sizeColumnsToFit();
			},
			downloadAsCSV() {
				let dataArray = [];
				this.rowData.forEach((row) => {
					dataArray.push([row.violationId, row.startTime, row.violationTypeName, row.violationSeverityName, row.cameraName]);
				});

				const csvString = [["Violation ID", "Start Time", "Violation Type Name", "Violation Severity Name", "Camera Name"], ...dataArray].map((e) => e.join(",")).join("\n");

				var csv_file, download_link;
				csv_file = new Blob([csvString], { type: "text/csv" });
				download_link = document.createElement("a");
				download_link.download = `Violation report.csv`;
				download_link.href = window.URL.createObjectURL(csv_file);
				download_link.style.display = "none";
				document.body.appendChild(download_link);
				download_link.click();
				document.body.removeChild(download_link);
			},
      getDifferenceInDays(date1, date2){
        let dur = intervalToDuration({start: date1, end: date2})
        dur = `${dur.days != 0 && dur.days ? dur.days+'d':''} ${dur.hours != 0 && dur.hours ? dur.hours+'h':''} ${dur.minutes}m ${dur.seconds}s`
        return dur;
      },
			async refreshTable(data) {
        console.log(data)
				let watchData = data;
				for (let i = 0; i < watchData.length; i++) {
					const w = watchData[i];
					watchData[i].watch = w;
          watchData[i].duration = watchData[i].endTime != null ? this.getDifferenceInDays(new Date(watchData[i].startTime), new Date(watchData[i].endTime)) : "In Progress";
				}
				this.defaultColDef = {
					resizable: true,
					sortable: true,
					filter: true,
					floatingFilter: true,
					suppressMenu: true,
					filterParams: {
						buttons: ["apply", "reset"],
						closeOnApply: true,
						suppressAndOrCondition: true,
					},
				};
				let self = this;
				this.columnDefs = [
					{ field: "violationId", header: "Incident ID", filter: true },
					{ field: "startTime", header: "Date/Time", filter: true, valueFormatter: this.dateTimeFormatter },
					{ field: "endTime", header: "Date/Time", filter: true, valueFormatter: this.dateTimeFormatter },
					{ field: "duration", header: "Date/Time", filter: true },
					{ field: "violationTypeName", header: "Incident Type", filter: true },
					{ field: "violationSeverityName", header: "Severity", filter: true },
					{ field: "cameraName", header: "Camera", filter: true },
					{
						field: "watch",
						headerName: "Actions",
						cellRenderer: WatchDeleteCellRenderer,
						resizable: false,
						filter: false,
						maxWidth: 80,
						cellRendererParams: {
							isOnlyEdit: false,
							isFeeds: false,
							isReport: true,
							clicked: async function (selectedData) {
								console.log(selectedData)
								self.violationId = selectedData.violationId;
								self.playerCamId = selectedData.cameraId;
								self.playerStartTime = selectedData.startTime;
								self.images = [];
								self.blobImages = [];
								self.isfetchingDone = false;
								self.playerEndTime = selectedData.endTime || new Date();
								self.fetchImages();
								self.watchVideoModal.show();
							},
              deleteThis: async function (selectedData) {
                self.incidentToDelete = selectedData.violationId;
                self.deleteIncidentModal.show();
							},
						},
					},
				];
				this.rowData = watchData.sort(function (a, b) {
          return b.startTime.localeCompare(a.startTime);
        });
				// console.log(watchData);
			},
      openTargetIncident(cameraId, startTime, endTime){
        this.playerCamId = cameraId;
        this.playerStartTime = startTime;
        this.images = [];
        this.blobImages = [];
        this.isfetchingDone = false;
        this.playerEndTime = endTime;
        this.fetchImages();
        this.watchVideoModal.show();
      },
			dateTimeFormatter(params) {
				if (params.value) {
					return format(parseISO(params.value), "yyyy-MM-dd HH:mm");
				} else {
					return null;
				}
			},
		},
		async mounted() {
			var modalElement;
      modalElement = document.getElementById("watchVideo_modal");
			this.watchVideoModal = new bootstrap.Modal(modalElement);
      modalElement = document.getElementById("deleteViolation_modal");
			this.deleteIncidentModal = new bootstrap.Modal(modalElement);
			await this.getFormData();

			let type = this.$route.query.type ? Number(this.$route.query.type) : null;

			let startTime = this.$route.query.startTime ? this.$route.query.startTime.split(" ") : null;
			let endTime = this.$route.query.endTime ? this.$route.query.endTime.split(" ") : null;
      		let cameraId = this.$route.query.id ? this.$route.query.id : null;

			startTime = startTime ? startTime.join("+") : null;
			endTime = endTime ? endTime.join("+") : null;

			// if(type){
			// 	// console.log(this.multiselectIncidentType)
			// 	this.multiselectIncidentType.value = [type];
			// }

			if(startTime){
				// this.dateRange[0]= new Date(startTime);
				// this.dateRange[1]= endTime ? new Date(endTime) : new Date(new Date(startTime).getTime() + 5*60000);
        		endTime = endTime ? new Date(endTime) : new Date(new Date(startTime).getTime() + 5*60000);
			}

			if(cameraId){
				endTime = endTime ? new Date(endTime) : new Date();
				this.openTargetIncident(cameraId, startTime, endTime)
			}

			await this.defaultSearch(
				this.dateRange[0],
				this.dateRange[1],
				type ? [type] : this.multiselectIncidentType.options.map((x) => {
					return x.value;
				}),
				this.multiselectIncidentSeverity.options.map((x) => {
					return x.value;
				})
			);
			this.api.sizeColumnsToFit();
		},
		setup() {
			setCurrentPageTitle("Incidents Report");
		},
	});
</script>

<style lang="scss">
	@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
	@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
	.video-images {
		max-width: 340px;
		max-height: 340px;
		border-radius: 12px;
	}
	.loader {
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #3498db; /* Blue */
		border-radius: 50%;
		width: 80px;
		height: 80px;
		animation: spin 1s linear infinite;
		margin: auto;
	}
	.timeText {
		font-size: 18px;
		padding-left: 35px;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
