
import useValidate from "@vuelidate/core";
import {
  required,
  helpers,
  sameAs,
} from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";

interface FormState {
  password: string;
  confirm: string;
}

export default defineComponent({
  name: "Password",
  props: {
    selectedUser: {
      required: true,
      type: Object,
    },
  },
  components: {},
  data() {
    return { errorMessageCustom: "" };
  },
  methods: {
    showPassword(id, e){
      let input = document.getElementById(id) as HTMLInputElement
      input.type = input.type == 'text' ? 'password' : 'text';
      console.log(e.target);
      e.target.classList.value = input.type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'
    },
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          let payload = {
            userId: this.selectedUser.userId,
            email: this.selectedUser.email,
            firstName: this.selectedUser.firstName,
            lastName: this.selectedUser.lastName,
            preferences: this.selectedUser
              ? this.selectedUser.preferences
              : "",            
            password: this.state.password,
          };
          let res = await ApiService.postData("User", payload, {});
          if (res.status === 200) {
            // ok
            this.$emit("closePasswordModal");
          }
        } catch (exception: any) {
          console.log(exception.response.status);
          
          if (exception.response.status === 461) {
            this.toggleSamePassword()
          } else {
            this.showErrorMessage("Error occured please try again later.");
          }
          console.log(exception);
        }
      }
    },
    toggleSamePassword(){
      let self = this;
      this.samePassword = true;
      setTimeout(() => {
        self.samePassword = false;
      }, 5000);
    },
    discard() {
      console.log("discard");
      this.$emit("verifyParamsSubmitCompleted");
    },
  },

  setup(props, context) {
    // create user form
    console.log("props: ", props);
    let userId = 0;
    let state: FormState = reactive({
      password: "",
      confirm: "",
    });

    const checkPassword = (value) => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return value.match(strongRegex);
    }

    const mustMatch = (value) => checkPassword(value);

    const rules = computed(() => {
      return {
        password: {
          required: helpers.withMessage("Password is required.", required),
          mustMatch: helpers.withMessage(
            "Required at least one digit , one lower case , one upper case and 8 from the mentioned characters.",
            mustMatch
          ),
        },
        confirm: {
          required: helpers.withMessage("Please confirm Passsword", required),
          sameAs: helpers.withMessage(
            "Value doesn't match Password",
            sameAs(state.password)
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditUserForm");
    };
    let samePassword = ref(false);

    return {
      samePassword,
      //  create new user
      state,
      closeForm,
      v$,
      userId,
      //  end create new user
    };
  },
});
