<template>
	<div class="row">
		<div class="card">
			<div class="card-body">
				<div class="form-group mb-5">
					<label for="alert_name">Alert Name</label>
					<input type="text" class="form-control" id="alert_name" placeholder="Alert name" v-model="alertName" />
					<p v-if="isAlertError" class="text-danger">Name is required !</p>
				</div>
				<div class="form-group mb-5">
					<label for="incident_type">Incident Type</label>
					<Multiselect v-model="multiselectViolationTypes.value" mode="single" class="customMultiselect" ref="multiselect_alert_violationType" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectViolationTypes.options" />
					<p v-if="isViolationTypeError" class="text-danger">Violation Type is required !</p>
				</div>
				<div class="form-group mb-5" v-if="multiselectViolationTypes.value && violationTypes.find(x => x.violationTypeId == multiselectViolationTypes.value ).violationCategory != 'Forklifts' && !immediately">
					<label for="time_limit_threshold">Time Limit Threshold (min)</label>
					<Multiselect v-model="multiselectTimeLimitThresholds.value" mode="single" class="customMultiselect" ref="multiselect_alert_time_limit_threshold" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectTimeLimitThresholds.options" />
					<p v-if="isTimeThresholdsError" class="text-danger">Time Limit Threshold  is required !</p>
				</div>
				<div class="form-group mb-5" v-if="multiselectViolationTypes.value && violationTypes.find(x => x.violationTypeId == multiselectViolationTypes.value ).violationCategory == 'Forklifts'">
					<label for="speed_threshold">Speed Threshold (km/h)</label>
					<Multiselect v-model="multiselectSpeedThresholds.value" mode="single" class="customMultiselect" ref="multiselect_alert_speed_threshold" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectSpeedThresholds.options" />
					<p v-if="isSpeedThresholdError" class="text-danger">Speed Threshold is required !</p>
				</div>
				<div class="form-group mb-5">
					<h5>Notification Frequency</h5>
					<div class="form-check mb-5 mx-5">
						<input class="form-check-input" type="checkbox" id="immediate_alert" v-model="immediately" :checked="multiselectTimeLimitThresholds.value ? false : immediately" />
						<label class="form-check-label" for="immediate_alert"> Immediately </label>
					</div>
					<div class="form-check mb-5 mx-5">
						<input class="form-check-input" type="checkbox" id="weekly_alert" v-model="weekly" :checked="weekly" />
						<label class="form-check-label" for="weekly_alert"> Weekly </label>
					</div>
				</div>
				<div class="form-group mb-5">
					<label for="exampleFormControlTextarea1">Recipients (,) separated</label>
					<textarea class="form-control mb-5" id="exampleFormControlTextarea1" rows="3" v-model="recipients"></textarea>
					<div v-if="recipientsList.length > 0" class="valid-emails-container">
						<p>Valid emails that will be included:</p>
						<span v-for="email in recipientsList" :key="email"> {{ email }} <br /> </span>
					</div>
				</div>
				<div class="row">
					<div class="col text-end">
						<button class="btn btn-secondary mx-1" @click="resetData">Cancel</button>
						<button class="btn btn-primary" @click="saveData">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
	import Multiselect from "@vueform/multiselect/dist/multiselect";

	export default defineComponent({
		components: { Multiselect },
		data() {
			return {
				multiselectViolationTypes: { value: null, options: [] },
				multiselectTimeLimitThresholds: { value: null, options: [5,10,15,20,25,30,35,40,45,50,55,60] },
				multiselectSpeedThresholds: { value: null, options: [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100] },
				alertName: "",
				immediately: false,
				weekly: false,
				recipients: "",
				isAlertError: false,
				isViolationTypeError: false,
				isSpeedThresholdError: false,
				isTimeThresholdsError: false,
				violationTypes: [],
			};
		},
		computed: {
			recipientsList: function () {
				let list = this.recipients.replace(/\s/g, "").split(",");
				for (let i = 0; i < list.length; i++) {
					const email = list[i];
					if (!this.validateEmail(email)) {
						list.splice(i, 1);
						i--;
					}
				}
				return list;
			},
		},
		methods: {
			validateEmail(input) {
				var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

				if (input.match(validRegex)) {
					return true;
				} else {
					return false;
				}
			},
			async getFormData() {
				let res = await ApiService.get("ViolationType");
				if (res.status == 200) {
					this.violationTypes = res.data;
					res.data.forEach((vio) => {
						this.multiselectViolationTypes.options.push({ value: vio.violationTypeId, label: vio.violationTypeName , category: vio.violationCategory });
					});
				}
			},
			async saveData() {
				let body = {
					alertConfigurationName: this.alertName,
					violationTypeId: this.multiselectViolationTypes.value,
					isEnabled: true, 
					notifyImmediately: this.immediately,
					notifyWeekly: this.weekly,
					recipients: this.recipientsList.toString(),
				};

				if (this.alertName == null || this.alertName == "") {
					this.isAlertError = true;
				} else {
					this.isAlertError = false;
				}

				if (this.multiselectViolationTypes.value == null || this.multiselectViolationTypes.value == "") {
					this.isViolationTypeError = true;
				} else {
					this.isViolationTypeError = false;
				}

				if((this.multiselectTimeLimitThresholds.value == null || this.multiselectTimeLimitThresholds == "") && !this.immediately){
					this.isTimeThresholdsError = true;
				} else {
					this.isTimeThresholdsError = false;
				}

				if(this.multiselectSpeedThresholds.value == null || this.multiselectSpeedThresholds == ""){
					this.isSpeedThresholdError = true;
				} else {
					this.isSpeedThresholdError = false;
				}

				if (!this.isAlertError && !this.violationTypeId && !this.isViolationTypeError && !this.isTimeThresholdsError && !this.isSpeedThresholdError) {
					let res = await ApiService.postData("AlertConfiguration", body, {});
					if (res.status == 200) {
						console.log(res);
						await this.resetData();
					}
				}
			},
			async resetData() {
				this.multiselectViolationTypes = { value: null, options: [] };
				this.multiselectTimeLimitThresholds = { value: null, options: [5,10,15,20,25,30,35,40,45,50,55,60] };
				this.multiselectSpeedThresholds = { value: null, options: [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100] };
				this.alertName = "";
				this.immediately = false;
				this.weekly = false;
				this.recipients = "";
				this.isAlertError = false;
				this.isViolationTypeError = false;
				this.isTimeThresholdsError = false;
				this.isSpeedThresholdError = false;
			},
		},
		async mounted() {
			await this.getFormData();
		},
		setup() {
			setCurrentPageTitle("New Alert");
		},
	});
</script>
<style lang="scss" scoped>
	.customMultiselect {
		border-radius: 5px;
	}

	:deep(.multiselect-option.is-selected) {
		background: #428bca !important;
	}
	.valid-emails-container {
		border-radius: 15px;
		box-shadow: inset 0px 0px 15px 1px grey;
		padding: 2%;
	}
</style>
