
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs, requiredIf } from '@vuelidate/validators';
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  email: string;
  password: string;
}

export default defineComponent({
  name: "EditUser",
  props: {
    selectedUser: {
      required: true,
      type: Object,
    },
    initialUser: {
      required: true,
    },
    users: {
      required: true,
      type: Array,
    },
  },
  components: {},
  data() {
    return {
      errorMessageCustom: "",
    };
  },
  mounted(){
    console.log("mounted now")
  },
  updated() {
    if (this.selectedUser && this.userId != this.selectedUser.userId) {
      this.userId = this.selectedUser.userId;
      this.state.firstName = this.selectedUser.firstName;
      this.state.lastName = this.selectedUser.lastName;
      this.state.email = this.selectedUser.email;
      this.state.isAdmin = this.selectedUser.isAdmin;
      this.state.password = "";

      this.allUsers = this.users.filter((value) => {
        let val = value as any;
        let selected = this.selectedUser as any;
        return val.email != selected.email;
      });
    }

    if(!this.selectedUser){
      this.allUsers = this.users.filter((value) => {
        let val = value as any;
        return val.email;
      });
    }

  },
  methods: {
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const initialUser = this.initialUser as any;
          const payload: any = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            preferences: this.selectedUser
              ? this.selectedUser.preferences
              : "",            
            isLocationAdmin: this.state.isAdmin,
            email: this.state.email,
            password: this.state.password,
          };
          if (this.selectedUser) {
            payload.userId = this.selectedUser.userId;
            // delete payload.password
          }

          if (!payload) {
            return;
          } else {
            const response = await ApiService.postData("User", payload, {});

            if (response.status === 200) {
              if (this.selectedUser) {
                this.$emit("closeEditUserForm");
              } else {
                this.$emit("closeAddUserForm");
              }
            } else {
              console.error(response);
              this.showErrorMessage("Error occured please try again later.");
            }
          }
        } catch (ex) {
          console.warn(ex);
        }
      }
    },
    discard() {
      this.$emit("verifyParamsSubmitCompleted");
    },
  },

  setup(props, context) {
    // create user form
    let userId = 0;
    let state: FormState = reactive({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      isAdmin: false,
    });


    let allUsers = props.users;
    if (props.selectedUser) {
      allUsers = props.users.filter((value) => {
        let val = value as any;
        let selected = props.selectedUser as any;
        return val.email != selected.email;
      });

      let user_to_edit = props.selectedUser as Models.UserToEdit;
      userId = user_to_edit.userId;
      let stateEdit: any = reactive({
        firstName: user_to_edit.firstName,
        lastName: user_to_edit.lastName,
        email: user_to_edit.email,
        password: "",
        isAdmin: user_to_edit.isAdmin,
      });

      state = stateEdit;
    }

    const checkEmail = (value) => {
      let flag = true;
      allUsers.forEach((val: any) => {
        if (val.email.toLowerCase() == value.toLowerCase()) {
          flag = false;
          return flag;
        }
      });
      return flag;
    };

    const checkPassword = (value) => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return value.match(strongRegex);
    }

    const mustBeUniqueEmail = (value) => checkEmail(value);
    const mustMatch = (value) => checkPassword(value);

    const rules: any = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First name is required.", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required.", required),
        },
        email: {
          required: helpers.withMessage("Email is required.", required),
          mustBeUniqueEmail: helpers.withMessage(
            "Email must be unique.",
            mustBeUniqueEmail
          ),
        },
        password: {
          required: helpers.withMessage("Password is required.", requiredIf(props.selectedUser == null)),
          mustMatch: helpers.withMessage(
            "Required at least one digit , one lower case , one upper case and 8 from the mentioned characters.",
            mustMatch
          ),
        }
      };
    });

    let v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditUserForm");
    };

    return {
      //  create new user
      state,
      closeForm,
      v$,
      userId,
      allUsers
      //  end create new user
    };
  },
});
