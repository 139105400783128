<template>
	<div class="card" style="width: 100%; height: 100%">
		<!--begin::Header-->
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label fw-bolder fs-3 mb-1">Cameras</span>
			</h3>
			<h3 class="card-title align-items-end flex-column">
				<div class="card-toolbar card-label fw-bolder fs-3 mb-1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a Camera">
					<a @click="addCamera" class="btn btn-sm btn-light-primary button-font-shadow shadow" style="margin-right: -1rem">
						<i class="fas fa-box-open"></i>
						New Camera
					</a>
				</div>
			</h3>
		</div>
		<!--end::Header-->

		<!--begin::Body-->
		<div class="card-body py-3 row d-flex justify-content-center">
			<!--begin::Table container-->
			<ag-grid-vue :defaultColDef="defaultColDef" :columnDefs="columnDefs" :rowData="rowData" :rowHeight="35" @first-data-rendered="gridSizeChanged" @grid-ready="onGridReady" @gridSizeChanged="gridSizeChanged" style="width: 100%; height: 99%" class="ag-theme-balham" id="camerasGrid"> </ag-grid-vue>
			<!--end::Table container-->
		</div>
		<!--begin::Body-->
	</div>

	<!-- start modal -->
	<div class="modal fade" tabindex="-1" id="editCamera_modal" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Edit Camera</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<EditCamera v-if="editCameraModalObject && cameras" v-model:selectedCamera="editCameraModalObject" :cameras="cameras" @closeEditCameraForm="closeEditCameraForm" @editedCameraSuccessfully="editedCameraSuccessfully" />
			</div>
		</div>
	</div>
	<!-- start modal -->
	<div class="modal fade" tabindex="-1" id="addCamera_modal" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Add Camera</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<EditCamera v-if="addCameraVisible && cameras" :selectedCamera="null" :cameras="cameras" @closeEditCameraForm="closeEditCameraForm" @editedCameraSuccessfully="editedCameraSuccessfully" />
			</div>
		</div>
	</div>
	<!-- end modal -->

	<!-- start delete camera confirmation -->
	<div class="modal fade" tabindex="-1" id="deleteCamera_modal" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" v-if="cameraToDelete">Delete Camera {{ cameraToDelete.cameraName }}</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<div class="modal-body">
					<p v-if="cameraToDelete">Are you sure you want to delete camera {{ cameraToDelete.cameraName }}?</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
					<button v-if="cameraToDelete" type="button" class="btn btn-danger" @click="deleteCamera(cameraToDelete.cameraId)">Delete</button>
				</div>
			</div>
		</div>
	</div>
	<!-- end delete camera confirmation -->
	<!--end::Tables Widget 9-->

	<!-- Cam Modal -->
	<div class="modal fade" id="watch_modal" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Camera Screen</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="playerCameraId = null"></button>
				</div>
				<div class="modal-body">
					<p class="text-center" v-if="isfetchingDone && !blobImages.length > 0" style="font-size: 18px">No Images Found !!!</p>
					<div class="text-center" v-if="!isfetchingDone">
							<div class="loader"></div>
					</div>
					<CustomImageRoller :blobImages="blobImages" ref="customImgRoller" v-if="blobImages.length > 0" :allowedDates="allowedDates" :datePickerActive="true" :startFromEnd="true"/>
					<!-- <div id="carousel_controls" class="carousel slide" data-bs-touch="false" data-bs-interval="false" v-if="blobImages.length > 0">
						<div class="carousel-inner ml-3 mr-3">
							<div class="carousel-item active">
								<div class="row d-flex align-items-center">
									<div class="col-9">
										<p class="timeText">{{ blobImages[imageIndex].time.toString().split(" ")[0] }} {{ blobImages[imageIndex].time.toString().split(" ")[1] }} {{ blobImages[imageIndex].time.toString().split(" ")[2] }} {{ blobImages[imageIndex].time.toString().split(" ")[3] }} {{ blobImages[imageIndex].time.toString().split(" ")[4] }} local time</p>
									</div>
									<div class="col-3" style="padding-right: 45px;">
										<Datepicker v-model="indexedDate" :allowedDates="allowedDates" autoApply class="mb-5" @update:modelValue="changeImageDay()" />
									</div>
								</div>
								<img class="d-block w-100 customImage" :src="blobImages[imageIndex].url" :alt="imageIndex" />
							</div>
						</div>

						<span class="carousel-control-prev leftArrow" @click="previous">&lsaquo;</span>
						<span v-if="imageIndex != 0" class="carousel-control-next rightArrow" @click="next">&rsaquo;</span>
					</div> -->
					<!-- <div class="row my-5" v-if="blobImages.length > 0">
						<div class="col" v-for="(dayObj, i) in blobImagesDays" :key="i">
							<button :class="`day-button ${dayObj.day == checkCurretDay ? 'day-button-active' : ''}`" @click="changeIndexTo(dayObj.index, $event)">{{ dayObj.day }}</button>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CustomImageRoller from "@/components/Player/CustomImageRoller.vue";
	import * as bootstrap from "bootstrap";
	import EditCamera from "./EditCamera.vue";
	import { AgGridVue } from "ag-grid-vue3";
	import { defineComponent } from "vue";
	import ApiService from "@/core/services/ApiService";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
	import WatchVideoCellRenderer from "@/components/settings/AgGridCells/WatchVideoCellRenderer.vue";

	export default defineComponent({
		name: "Cameras",
		components: { AgGridVue, EditCamera, CustomImageRoller },
		props: {},
		data() {
			return {
				addModal: null,
				editModal: null,
				deleteModal: null,
				cameraToDelete: null,
				allowedDates: [],
				indexedDate: null,

				playerCameraId: null,

				watchVideoModal: null,
				images: [],
				blobImages: [],
				isfetchingDone: true,
				imageIndex: 0,
				blobImagesDays: [],

				cameras: null,
				defaultColDef: null,
				columnDefs: null,
				rowData: null,
				api: null,
				columnApi: null,
				addCameraVisible: false,
				editCameraVisible: false,
				editCameraModalObject: null,
			};
		},

		mounted() {
			setCurrentPageTitle("Cameras");
			// addCamera_modal
			let modalElement = document.getElementById("addCamera_modal");
			this.addModal = new bootstrap.Modal(modalElement);
			if (modalElement) {
				var self = this;
				modalElement.addEventListener("hidden.bs.modal", function (event) {
					self.addCameraVisible = false;
				});
			}

			modalElement = document.getElementById("editCamera_modal");
			this.editModal = new bootstrap.Modal(modalElement);
			if (modalElement) {
				var self = this;
				modalElement.addEventListener("hidden.bs.modal", function (event) {
					self.editCameraModalObject = null;
					self.editCameraVisible = false;
				});
			}

			modalElement = document.getElementById("watch_modal");
			this.watchVideoModal = new bootstrap.Modal(modalElement);
			if (modalElement) {
				var self = this;
				modalElement.addEventListener("hidden.bs.modal", function (event) {});
			}
		},

		async beforeMount() {
			this.refreshTable();
		},
		computed: {},
		methods: {
			checkBlobImageDateTime() {
				this.indexedDate = this.blobImages[this.imageIndex].time;
			},
			getTimesInDates() {
				// console.log(this.blobImages);
				for (let i = 0; i < this.blobImages.length; i++) {
					const imgObj = this.blobImages[i];
					const imgObjSplit = imgObj.time.toString().split(" ");
					const imgObjDay = `${imgObjSplit[2]} ${imgObjSplit[1]} ${imgObjSplit[3]}`;
					const imgObjTime = imgObjSplit[4];
				}
			},
			changeImageDay() {
				let day = this.indexedDate.toString().split(" ");
				// console.log(this.indexedDate);
				let dateMade = `${day[2]} ${day[1]} ${day[3]}`;
				// this.imageIndex = this.blobImagesDays.find(x=> x.day == dateMade).index;
				let nearestImageIndex = this.getNearestDateWithTime();
				// console.log(nearestImageIndex);
				if (nearestImageIndex < this.blobImages.length && nearestImageIndex >= 0) {
					this.imageIndex = this.getNearestDateWithTime();
				} else {
					this.imageIndex = this.blobImagesDays.find((x) => x.day == dateMade).index;
				}
			},

			getNearestDateWithTime() {
				let differences = [];
				for (let i = 0; i < this.blobImages.length; i++) {
					let time = this.blobImages[i].time;
					let diff = time - this.indexedDate;
					differences.push(Math.abs(diff));
				}
                // console.log("min: ", Math.min(Math.abs(...differences)))
				return differences.indexOf(Math.min(...differences));
			},

			getDays(array) {
				let days = [];
				for (let i = 0; i < array.length; i++) {
					const elt = array[i].time.toString().split(" ");
					let day = `${elt[2]} ${elt[1]} ${elt[3]}`;
					if (!days.find((x) => x.day == day)) {
						if (days.length == 0) {
							this.indexedDate = array[i].time;
						}
						days.push({ day: day, index: i });
						this.allowedDates.push(array[i].time);
					}
				}
				this.blobImagesDays = days;
				this.getTimesInDates();
			},
			confirmCameraDeletion(data) {
				this.cameraToDelete = data;
				let modalElement = document.getElementById("deleteCamera_modal");
				if (modalElement) {
					modalElement.addEventListener("hidden.bs.modal", function (event) {});
				}
				this.deleteModal = new bootstrap.Modal(modalElement);
				this.deleteModal.show();
			},
			onGridReady(params) {
				this.api = params.api;
				this.columnApi = params.columnApi;
			},
			gridSizeChanged() {
				this.api.sizeColumnsToFit();
			},
			// triggerGridSize
			async refreshTable() {
				this.defaultColDef = {
					resizable: true,
					sortable: true,
					filter: true,
					floatingFilter: true,
					suppressMenu: true,
					filterParams: {
						buttons: ["apply", "reset"],
						closeOnApply: true,
						suppressAndOrCondition: true,
					},
				};

				const camerasData = await ApiService.get("Camera");
				let finalcamerasData = camerasData.data;
				this.cameras = finalcamerasData;

				for (let index = 0; index < finalcamerasData.length; index++) {
					const camera = finalcamerasData[index];
					finalcamerasData[index].edit = camera;
					finalcamerasData[index].watch = camera;
				}
				let self = this;
				this.columnDefs = [
					{
						field: "cameraId",
						headerName: "Camera Id",
						filter: true,
						resizable: true,
					},
					{
						field: "cameraName",
						headerName: "Camera Name",
						filter: true,
						resizable: true,
					},
					{
						field: "edit",
						headerName: "Actions",
						sortable: false,
						filter: false,
						cellRenderer: BtnCellRederer,
						resizable: true,
						maxWidth: "100",
						cellRendererParams: {
							isOnlyEdit: false,
							isFeeds: true,
							clicked: function (selectedData) {
								self.editCameraModalObject = null;
								self.changeSelectedCamera(selectedData);
								self.editCameraVisible = true;
							},
							deleteClicked: function (selectedData) {
								self.confirmCameraDeletion(selectedData);
							},
						},
					},
					{
						field: "watch",
						headerName: "",
						sortable: false,
						filter: false,
						cellRenderer: WatchVideoCellRenderer,
						resizable: false,
						maxWidth: 80,
						cellRendererParams: {
							isOnlyEdit: false,
							isFeeds: true,
							isReport: false,
							clicked: async function (selectedData) {
								self.playerCameraId = selectedData.cameraId;
								self.images = [];
								self.blobImages = [];
								self.isfetchingDone = false;
								self.imageIndex = 0;
								await self.openCameraModal();
							},
						},
					},
				];
				this.rowData = finalcamerasData;
			},
			async deleteCamera(id) {
				let res = await ApiService.delete(`/Camera/${id}`);
				if (res.status === 200) {
					this.refreshTable();
					this.deleteModal.hide();
				} else {
					alert("Something wen wrong while deleting camera.");
				}
			},
			editedCameraSuccessfully() {
				this.closeEditCameraForm();
				this.refreshTable();
			},
			addCamera() {
				this.addModal.show();
				this.addCameraVisible = true;
			},
			changeSelectedCamera(camera) {
				this.editModal.show();
				this.editCameraModalObject = camera;
			},
			closeEditCameraForm() {
				this.editModal.hide();
				this.addModal.hide();
			},
			minusDays(date, days) {
				var result = new Date(date);
				result.setDate(result.getDate() - days);
				return result;
			},
			async openCameraModal() {
				this.watchVideoModal.show();
				await this.fetchImages();
			},
			async fetchImages() {
				let token = await ApiService.get("Stream/SAS");

				token = token.data.split("?")[1];
				let payload = { cameraId: this.playerCameraId, startTime: this.minusDays(new Date(), 7).toISOString(), endTime: new Date().toISOString() };

				await ApiService.post("Violation/LatestImages", payload).then((response) => {
					if (response.data) {
						this.images = response.data;
						this.isfetchingDone = true;
					}
				});

				if (this.images.length > 0) {
          console.log(this.images)
					for (let j = 0; j < this.images.length; j++) {
						const imgURL = `${this.images[j].imageUrl}?${token}`;
            if(this.images[j].videoUrl){
              let vidUrl = `${this.images[j].videoUrl}?${token}`;
              this.blobImages.push({ url: imgURL, videoUrl: vidUrl, time: new Date(this.images[j].fileTimestamp) });
            }else{
              this.blobImages.push({ url: imgURL,videoUrl: null, time: new Date(this.images[j].fileTimestamp) });
            }
					}
					this.blobImages.reverse();
					this.getDays(this.blobImages);

					this.imageIndex = 0;
				}
			},
			previous() {
				if (this.imageIndex == this.blobImages.length) {
					this.imageIndex = 0;
				} else {
					this.imageIndex += 1;
				}
				this.checkBlobImageDateTime();
			},
			next() {
				this.imageIndex -= 1;
				this.checkBlobImageDateTime();
			},
		},
	});
</script>

<style lang="scss">
	@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
	@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
	.video-images {
		width: 100%;
		max-height: 340px;
		border-radius: 12px;
	}

	.leftArrow {
		margin-left: -70px;
		color: black !important;
		font-size: 120px;
		cursor: pointer;
		user-select: none;
	}

	.rightArrow {
		margin-right: -70px;
		color: black !important;
		font-size: 120px;
		cursor: pointer;
		user-select: none;
	}

	.customSpinner {
		width: 3rem;
		height: 3rem;
	}

	.timeText {
		font-size: 18px;
		padding-left: 35px;
	}

	.customImage {
		max-height: 530px !important;
		padding-right: 35px;
		padding-left: 35px;
		border-radius: 5px;
	}
	.day-button {
		background: none;
		border-radius: 5px;
		color: grey;
		border: 1px solid grey;
		padding: 5px;
	}
	.day-button:hover {
		color: #009ef7;
		border-color: #009ef7;
	}
	.day-button-active {
		color: #009ef7;
		border-color: #009ef7;
	}
	.dp__action.dp__cancel {
		color: white;
		padding: 5px !important;
		background-color: #999;
		margin-right: 5px;
	}
	.dp__action.dp__select {
		color: white;
		background-color: #0095e8;
		padding: 5px !important;
	}
		.loader {
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #3498db; /* Blue */
		border-radius: 50%;
		width: 80px;
		height: 80px;
		animation: spin 1s linear infinite;
		margin: auto;
	}
		@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

</style>
