<template>
	<div class="row">
		<div class="card">
			<div class="card-body">
				<div class="row mb-5 fs-3">
					<div class="col-1 text-center">
						<label>ID</label>
					</div>
					<div class="col-5">
						<label>Alert Name</label>
					</div>
					<div class="col-2">
						<label>Status</label>
					</div>
					<div class="col-4 text-end">
						<label>Manage</label>
					</div>
				</div>
				<hr>
				<div v-if="alertList.length > 0">
					<div v-for="(item, index) in alertList" :key="index">
						<single-alert :id=item.alertConfigurationId :name="`${item.alertConfigurationName}`" :status=item.isEnabled @toggleAlert="toggleAlert" @openDeleteAlertModal="openDeleteAlertModal" @openEditAlertModal="openEditAlertModal"/>
					</div>
				</div>
				<p class="text-center" v-if="alertList.length == 0">No records found.</p>
			</div>
		</div>
	</div>

	<!-- start edit alert modal -->
	<div class="modal fade" tabindex="-1" id="editAlert_modal" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog">
		<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Edit Alert</h5>

			<!--begin::Close-->
			<div
			class="btn btn-icon btn-sm btn-active-light-primary ms-2"
			data-bs-dismiss="modal"
			aria-label="Close"
			>
			<span class="svg-icon svg-icon-2x"></span>
			<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
			</div>
			<!--end::Close-->
		</div>
		<div class="modal-body" v-if="alertToEdit">
				<div class="form-group mb-5">
					<label for="alert_name">Alert Name</label>
					<input type="text" class="form-control" id="alert_name" placeholder="Alert name" v-model="alertToEdit.alertConfigurationName" />
					<p v-if="alertToEdit.alertConfigurationName == null || alertToEdit.alertConfigurationName == ''" class="text-danger">Name is required !</p>
				</div>
				<div class="form-group mb-5">
					<label for="incident_type">Incident Type</label>
					<Multiselect v-model="alertToEdit.violationTypeId" mode="single" class="customMultiselect" ref="multiselect_alert_violationType" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectViolationTypes.options" />
					<p v-if="alertToEdit.violationTypeId == null || alertToEdit.violationTypeId == ''" class="text-danger">Violation Type is required !</p>
				</div>
				<div class="form-group mb-5" v-if="alertToEdit.violationTypeId && violationTypes.find(x => x.violationTypeId == alertToEdit.violationTypeId ).violationCategory != 'Forklifts' && !immediately">
					<label for="time_limit_threshold">Time Limit Threshold (min)</label>
					<Multiselect v-model="multiselectTimeLimitThresholds.value" mode="single" class="customMultiselect" ref="multiselect_alert_time_limit_threshold" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectTimeLimitThresholds.options" />
					<p v-if="isTimeThresholdsError" class="text-danger">Time Limit Threshold  is required !</p>
				</div>
				<div class="form-group mb-5" v-if="alertToEdit.violationTypeId && violationTypes.find(x => x.violationTypeId == alertToEdit.violationTypeId ).violationCategory == 'Forklifts'">
					<label for="speed_threshold">Speed Threshold (km/h)</label>
					<Multiselect v-model="multiselectSpeedThresholds.value" mode="single" class="customMultiselect" ref="multiselect_alert_speed_threshold" :close-on-select="false" :searchable="true" :create-option="true" :options="multiselectSpeedThresholds.options" />
					<p v-if="isSpeedThresholdError" class="text-danger">Speed Threshold is required !</p>
				</div>
				<div class="form-group mb-5">
					<h5>Notification Frequency</h5>
					<div class="form-check mb-5 mx-5">
						<input class="form-check-input" type="checkbox" id="immediate_alert" v-model="alertToEdit.notifyImmediately" :checked="multiselectTimeLimitThresholds.value ? false : alertToEdit.notifyWeekly" />
						<label class="form-check-label" for="immediate_alert"> Immediately </label>
					</div>
					<div class="form-check mb-5 mx-5">
						<input class="form-check-input" type="checkbox" id="weekly_alert" v-model="alertToEdit.notifyWeekly" :checked="alertToEdit.notifyWeekly" />
						<label class="form-check-label" for="weekly_alert"> Weekly </label>
					</div>
				</div>
				<div class="form-group mb-5">
					<label for="exampleFormControlTextarea1">Recipients (,) separated</label>
					<textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="alertToEdit.recipients" ></textarea>
				</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" @click="closeEditModal()">
			Cancel
			</button>
			<button
			v-if="alertToEdit"
			type="button"
			class="btn btn-primary" 
			@click="editAlert(alertToEdit)">
			Save
			</button>
		</div>
	</div>
	</div>
	</div>
	<!-- end modal -->

	<!-- start delete alert confirmation -->
	<div class="modal fade" tabindex="-1" id="deleteAlert_modal" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog">
		<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" v-if="alertToDelete">Delete Alert {{ alertToDelete.alertConfigurationName }}</h5>

			<!--begin::Close-->
			<div
			class="btn btn-icon btn-sm btn-active-light-primary ms-2"
			data-bs-dismiss="modal"
			aria-label="Close"
			>
			<span class="svg-icon svg-icon-2x"></span>
			<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
			</div>
			<!--end::Close-->
		</div>
		<div class="modal-body">
			<p v-if="alertToDelete">
			Are you sure you want to delete alert <strong>{{ alertToDelete.alertConfigurationName }}</strong> ?
			</p>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" data-bs-dismiss="modal">
			Cancel
			</button>
			<button
			v-if="alertToDelete"
			type="button"
			class="btn btn-danger"
			@click="deleteAlert(alertToDelete.alertConfigurationId)"
			>
			Delete
			</button>
		</div>
		</div>
	</div>
</div>
</template>

<script>
	import SingleAlert from "@/components/Alerts/SingleAlert.vue";
	import Multiselect from "@vueform/multiselect/dist/multiselect";
	import { defineComponent } from "vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
	import * as bootstrap from "bootstrap";

	export default defineComponent({
		components: { SingleAlert , Multiselect },
		data() {
			return {
				alertList: [],
				deleteModal : bootstrap.Modal,
				editModal : bootstrap.Modal,
                alertToDelete : false ,
				alertToEdit : false,

				multiselectViolationTypes: { value: null, options: [] },
				multiselectTimeLimitThresholds: { value: null, options: [5,10,15,20,25,30,35,40,45,50,55,60] },
				multiselectSpeedThresholds: { value: null, options: [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100] },
				
				isSpeedThresholdError: false,
				isTimeThresholdsError: false,
				violationTypes: [],
			};
		},
		methods: {
			async getAlerts() {
				let res = await ApiService.get("AlertConfiguration");
				if (res.status == 200) {
					console.log(res.data);
					this.alertList = res.data;
				}
			},
			async toggleAlert(id){
				let item = this.alertList.find(x => x.alertConfigurationId == id);
				let body = {
					alertConfigurationId: item.alertConfigurationId,
					alertConfigurationName: item.alertConfigurationName,
					violationTypeId: item.violationTypeId,
					isEnabled: !item.isEnabled
				}

				if(item){
					let res = await ApiService.post("AlertConfiguration" , body , {});
					if (res.status == 200) {
						console.log('Toggle Alert' , res.data);
					}
				}
			},
			async openDeleteAlertModal(id){
				let item = this.alertList.find(x => x.alertConfigurationId == id);
				if(item){
					this.deleteModal.show();
					this.alertToDelete = item;
				}
			},
			async deleteAlert(id){
				let res = await ApiService.delete("AlertConfiguration?alertConfigurationId=" + id);
				if (res.status == 200) {
					console.log('Delete Alert' , res.data);
					this.deleteModal.hide();
					await this.getAlerts();
				}
			},
			async getViolationTypes() {
				let res = await ApiService.get("ViolationType");
				if (res.status == 200) {
					this.violationTypes = res.data;
					res.data.forEach((vio) => {
						this.multiselectViolationTypes.options.push({ value: vio.violationTypeId, label: vio.violationTypeName });
					});
				}
			},
			async openEditAlertModal(id){
				let item = this.alertList.find(x => x.alertConfigurationId == id);
				if(item){
					this.editModal.show();
					this.alertToEdit = item;
				}
			},
			async editAlert(body){

				if(body.alertConfigurationName == null || body.alertConfigurationName == ""){
					this.isAlertError = true;
				}else{
					this.isAlertError = false;
				}

				if(body.violationTypeId == null || body.violationTypeId == ""){
					this.isViolationTypeError = true;
				}else{
					this.isViolationTypeError = false;

					if(this.multiselectViolationTypes.value && violationTypes.find(x => x.violationTypeId == body.violationTypeId ).violationCategory == 'Forklifts'){
						if(this.multiselectSpeedThresholds.value == null || this.multiselectSpeedThresholds == ""){
							this.isSpeedThresholdError = true;
						} else {
							this.isSpeedThresholdError = false;
						}
					}else{
						if((this.multiselectTimeLimitThresholds.value == null || this.multiselectTimeLimitThresholds == "") && !this.immediately){
							this.isTimeThresholdsError = true;
						} else {
							this.isTimeThresholdsError = false;
						}
					}
				}

				if(!this.isAlertError && !this.violationTypeId && !this.isViolationTypeError && !this.isTimeThresholdsError && !this.isSpeedThresholdError){
					let res = await ApiService.postData("AlertConfiguration", body, {});
					if(res.status == 200){
						console.log(res);
						await this.closeEditModal();
					}
				}
			},
			async closeEditModal(){
				this.editModal.hide();
				await this.getAlerts();
			}
		},
		async mounted() {
			let modalElement = document.getElementById("deleteAlert_modal");
			this.deleteModal = new bootstrap.Modal(modalElement);
			if (modalElement) {
				var self = this;
				modalElement.addEventListener("hidden.bs.modal", function(event) {
					self.alertToDelete = false;
				});
			};

			let editModalElement = document.getElementById("editAlert_modal");
			this.editModal = new bootstrap.Modal(editModalElement);
			if (editModalElement) {
				var self = this;
				editModalElement.addEventListener("hidden.bs.modal", function(event) {
					self.alertToEdit = false;
					self.isAlertError = false;
					self.isViolationTypeError = false;
					self.isTimeThresholdsError = false;
					self.isSpeedThresholdError = false;
				});
			};

			await this.getAlerts();
			await this.getViolationTypes();
		},
		setup() {
			setCurrentPageTitle("Alerts");
		},
	});
</script>
<style lang="scss" scoped>
	.customMultiselect{
		border-radius: 5px;
	}

	:deep(.multiselect-option.is-selected) {
		background: #428bca !important;
	}
</style>
