import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export class LocationContext {
  route;
  store;
  /**
   *
   */
  constructor(route, store) {
    this.route = route;
    this.store = store;
  }

  async buildLocation(auth0) {
    const location = await (await ApiService.get("Location")).data;
    if (!location) throw "zero locations found";
    // console.log("CLINIC: ", location);
    this.store.commit(Mutations.SET_CLINIC, location);
  }
}
