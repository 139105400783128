
import { useStore } from "vuex";

import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  cameraId: number;
  cameraName: string;
}

export default defineComponent({
  name: "EditCamera",
  props: {
    selectedCamera: {
      required: true,
    },
    cameras: {
      required: true,
      type: Array,
    },
  },
  components: {},
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.state.cameraName) {
          let payload = {
            cameraId: this.state.cameraId,
            cameraName: this.state.cameraName,
          };
          if (this.selectedCamera) {
            let currentCamera = this.selectedCamera as Models.Camera;
            let editCameraPayload = {
              cameraId: currentCamera.cameraId,
              cameraName: this.state.cameraName,
            };
            payload = editCameraPayload;
          }

          try {
            if(this.selectedCamera == null){
              let editCamera = await ApiService.postData(
                "Camera?name=" + this.state.cameraName,
                payload,
                {}
              );
              if (editCamera.status === 200) {
                this.$emit("editedCameraSuccessfully");
              }

            }else{
              let putPayload = {
                cameraId: payload.cameraId,
                name: payload.cameraName
              }
              let editCamera = await ApiService.put(
                "Camera",
                putPayload,
                {}
              );
              if (editCamera.status === 200) {
                this.$emit("editedCameraSuccessfully");
              }
            }
          } catch (err) {
            console.warn(err);
          }
        }
      }
    },
    discard() {
      this.$emit("closeEditCameraForm");
    },
  },
  updated() {
    if (this.selectedCamera) {
      this.allCameras = this.cameras.filter((value) => {
        let val = value as any;
        let selected = this.selectedCamera as any;
        return val.cameraName != selected.cameraName;
      });
    }
    let camera_to_edit = this.selectedCamera as Models.Camera;
    if (
      this.selectedCamera &&
      camera_to_edit.cameraId != this.cameraId
    ) {
      this.allCameras = this.cameras.filter((value) => {
        let val = value as any;
        let selected = this.selectedCamera as any;
        return val.cameraName != selected.cameraName;
      });
      this.cameraId = camera_to_edit.cameraId;
      this.state.cameraName = camera_to_edit.cameraName;
    }
  },

  setup(props, context) {
    const store = useStore();
    const initialUser = store.getters.currentUser;
    let state: FormState = reactive({
      cameraId: 0,
      cameraName: "",
    });
    let cameraId = 0;
    let allCameras = props.cameras;
    if (props.selectedCamera) {
      allCameras = props.cameras.filter((value) => {
        let val = value as any;
        let selected = props.selectedCamera as any;
        return val.cameraName != selected.cameraName;
      });
      let camera_to_edit = props.selectedCamera as Models.Camera;
      let stateEdit: FormState = reactive({
        cameraId: camera_to_edit.cameraId,
        cameraName: camera_to_edit.cameraName,
      });
      state = stateEdit;
      cameraId = camera_to_edit.cameraId;
    }

    const checkCameraName = (value) => {
      let flag = true;
      allCameras.forEach((val: any) => {
        if (val.cameraName.toLowerCase() == value.toLowerCase()) {
          flag = false;
          return flag;
        }
      });
      return flag;
    };

    const mustBeUniqueName = (value) => checkCameraName(value);

    const rules = computed(() => {
      return {
        cameraName: {
          required: helpers.withMessage("Name is required.", required),
          mustBeUniqueName: helpers.withMessage(
            "Camera name must be unique.",
            mustBeUniqueName
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditCameraForm");
    };

    return {
      mustBeUniqueName,
      state,
      v$,
      closeForm,
      cameraId,
      allCameras,
      checkCameraName,
      initialUser,
    };
  },
});
