
import { defineComponent, ref } from "vue";
import { useAuth0 } from "@/auth/auth";
import ApiService from "@/core/services/ApiService";
import { UserContext } from "@/auth/UserContext";
import { LocationContext } from "@/auth/LocationContext";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { LocationInfo } from "@/store/modules/AuthModule";
import store from "@/store/";
// import { InstanceInfoContext } from '@/auth/InstanceInfoContext';

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
  },
  methods: {},
  async mounted() {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    const domain = "overwatchhv.us.auth0.com";
    const clientId = "VDYsrjOKxDHChrIB8yaF7baqVVZb6zR8";
    const audience = "https://api.overwatch.ca";

    const redirectTo = this.$route.query.redirectFrom ?? "dashboard";
    console.log("redirectTo", redirectTo);
    console.log("vue query", this.$route.query);
    console.log("window query", window.location.pathname);

    const auth0 = await useAuth0({
      domain,
      clientId,
      audience,
      redirectUri: `${window.location.origin}/login?redirectFrom=${redirectTo}`,
      onRedirectCallback: (appState) => {
        console.log("appstate", appState);
        this.$router.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      },
    });

    const params = new URL(window.location.href).searchParams;
    const routeLoginHint = params.get("u");

    console.log(auth0);

    if (auth0.loading) {
      throw "something is wrong";
    }

    if (!this.$auth.isAuthenticated) {
      console.log("not authenticated");

      if (!this.$auth.error) {
        if (routeLoginHint) {
          console.log("routeLoginHint", routeLoginHint);
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.$auth.loginWithRedirect({
            login_hint: routeLoginHint as string,
          });
        } else {
          console.log("loginWithRedirect", "login");
          this.$auth.loginWithRedirect({});
        }
        await ApiService.setAuthHeader();
      }
    } else {
      await ApiService.setAuthHeader();
    }

    const context = new UserContext(this.$route, this.$store);
    await context.buildCurrentUser(auth0);
    const locationContext = new LocationContext(this.$route, this.$store);
    await locationContext.buildLocation(auth0);
    // const instanceInfo = new InstanceInfoContext(this.$route, this.$store);
    // await instanceInfo.buildInstance();
    this.$router.push(redirectTo.toString());
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    
  },
});
