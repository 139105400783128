// import "ant-design-vue/dist/antd.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import KTAside from "@/layout/aside/Aside.vue";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import "@/core/plugins/keenthemes";
import ApiService from "@/core/services/ApiService";
import "bootstrap";
import { Store } from "vuex";
import { VueAuth } from './auth/VueAuth'
import { getInstance } from './auth/auth'
// import Antd from 'ant-design-vue';
import VueApexCharts from "vue3-apexcharts";
import "@/assets/customStyle/style.scss"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


//import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        //$msalInstance: PublicClientApplication;    
        $auth: VueAuth,
        //$currentAccount: AccountInfo;
        $store: Store<boolean>
    }
}
const app = createApp(App);
//app.config.globalProperties.$msalInstance = new PublicClientApplication(store.state.msalConfig,);
//app.config.globalProperties.$currentAccount = null;
app.config.globalProperties.$auth = getInstance();
ApiService.init(app);
app.use(store);
app.use(router);
app.use(ElementPlus);
initInlineSvg(app);
app.use(i18n);
app.use(VueApexCharts);
app.component('Datepicker', Datepicker);

// app.use(Antd);
app.mount("#app");
