<template>
  <apexchart height="392" type="area" :options="options" :series="series" v-if="done" :colors="['#F44336', '#E91E63', '#9C27B0']"></apexchart>
</template>

<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "AverageSpeed",
  components: {},
  props: {
    widgetClasses: String,
  },
  data: function () {
    return {
      done: false,
      options: {
        chart: {
          id: 'Speed-Avg'
        },
        xaxis: {
          type: "datetime",
          categories: []
        },
        dataLabels: {
          enabled: false,
        }
      },
      series: [{
        name: 'Speed Average (km/h)',
        data: []
      },
        {
          name: 'Max Speed (km/h)',
          data: []
        }],
    };
  },
  methods: {
    async getSpeedAverageSeries(){
      let today = new Date();
      let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()-7);

      let body = {
        startTime: lastWeek.toISOString(),
        endTime: today.toISOString(),
      };
      let res = await ApiService.postData("Report/Speed", body, {});
      if (res.status === 200) {
        let events = res.data.reverse();
        let days = []
        events.forEach(ev=>{
          let currentEvDay = ev.dateTimeHour.toLocaleString();
          if(!days.find(x=> x.day === currentEvDay)){
            days.push({day: currentEvDay, avg: [ev.avgTopSpeedKmH], max: [ev.maxTopSpeedKmH] })
          }else{
            days.find(x=> x.day === currentEvDay).avg.push(ev.avgTopSpeedKmH);
            days.find(x=> x.day === currentEvDay).max.push(ev.maxTopSpeedKmH);
          }
        })
        for (let i = 0; i < days.length; i++){
          let day = days[i];
          let dayavg = 0;
          let daymax = 0;
          for (let j = 0; j < day.avg.length; j++){
            dayavg += day.avg[j];
          }
          dayavg = dayavg / day.avg.length;
          days[i].avg = dayavg;
          for (let j = 0; j < day.max.length; j++){
            daymax += day.max[j];
          }
          daymax = daymax / day.max.length;
          days[i].max = daymax;
        }
        this.series[0].data = days.map(x=> x.avg);
        this.series[1].data = days.map(x=> x.max);
        this.options.xaxis.categories = days.map(x=> x.day);
        this.done = true;
      }
    },
  },
  async mounted() {
    await this.getSpeedAverageSeries();
  },
});
</script>

<style lang="scss" scoped>

</style>