<template>
<div class="row mh-400">
  <div class="col-12 text-end">
    <select name="camera" id="cam" class="form-control" v-model="selectedCamera" @change="fetchImages" disabled>
      <option :value="null">Select a Camera</option>
      <option v-for="(cam, i) in cameras" :key="i" :value="cam.cameraId">
        {{cam.cameraName}}
      </option>
    </select>
  </div>
  <div class="col-12">
    <img v-if="blobImage" :src="blobImage.url" ref="camPrev" style="width: 100%; max-height: 325px;" alt="Camera Preview">
    <p class="text-center" v-else>Could not find any image</p>
  </div>

</div>
</template>

<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
var Buffer = require("buffer/").Buffer;

export default defineComponent({
  name: "MultipleCamPreview",
  components: {},
  props: {},
  data: function () {
    return {
      selectedCamera: null,
      cameras: [],
      blobImage: null,


    };
  },
  methods: {
    async fetchImages() {
      console.log("fetching images for ", this.selectedCamera)
      let days = [];
      let token = await ApiService.get("Stream/SAS");
      token = token.data.split("?")[1];
      let self = this;
      let today = new Date();
      let week = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

      let payload = { cameraId: this.selectedCamera, startTime: week, endTime: today };
      let images;
      await ApiService.post("Violation/LatestImages", payload).then((response) => {
        if (response) {
          images = response.data;
          if(images.length === 0){
            this.blobImage = null;
            return;
          }
        }
      });
      console.log(`images len ${images.length}`)
      if (images.length > 0) {
        let j = images.length -1;
        const imgURL = `${images[j].imageUrl}?${token}`;
        this.blobImage = { url: imgURL, time: new Date(images[j].fileTimestamp) };
      } else {
        this.isfetchingDone = true;
      }
    },
    async getCameras(){
      let res = await ApiService.get("Camera");
      if (res.status === 200) {
        this.cameras = res.data;
        console.log(res.data)
      }
    },
  },
  async mounted() {
    await this.getCameras();
    if(this.cameras.length > 0){
      this.selectedCamera = 9;
      await this.fetchImages();
    }
  },
});
</script>

<style lang="scss" scoped>
.mh-400{
  max-height: 420px;
  min-height: 420px;
}
</style>