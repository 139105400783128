<template>
	<div class="row mb-5">
		<h5>Search</h5>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="form-group mb-5 col-11">
						<label for="date_range">Date Range</label>
						<Datepicker v-model="dateRange" :multiCalendars="true" range id="date_range" :presetRanges="presetRanges" :format="rangeFormat" :enableTimePicker="false" />
					</div>
					<div class="col-1 text-end mt-4">
						<button class="btn btn-primary" @click="search">Search</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<h5>Data Report</h5>
		<div class="card">
			<div class="card-body">
				<div class="col text-end">
					<button title="download as excel" class="btn btn-icon btn-active-light-primary" @click="downloadCSVFile('HangarReport.csv')"><i class="fa-regular fa-circle-down fs-20-px"></i></button>
				</div>
				<div class="row table-responsive h-100">
					<div class="" style="height: calc(100vh - 60vh)">
						<ag-grid-vue :defaultColDef="defaultColDef" :columnDefs="columnDefs" :rowData="rowData" :rowHeight="35" @grid-ready="onGridReady" @gridSizeChanged="gridSizeChanged" style="width: 100%; height: 100%" class="ag-theme-balham"> </ag-grid-vue>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue3";
	import { defineComponent } from "vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
	import { endOfMonth, endOfYear, format, startOfMonth, startOfYear, subMonths, subDays, parseISO } from "date-fns";

	const EventType = {
		1: "Aircraft Enters",
		2: "Aircraft Exits",
		3: "Fences Up",
		4: "Fences Down",
	};
	export default defineComponent({
		components: { AgGridVue },
		data() {
			return {
				events: [],
				dateRange: [startOfMonth(new Date()), endOfMonth(new Date())],

				// date range picker presets
				presetRanges: [
					{ label: "Today", range: [new Date(), new Date()] },
					{ label: "Yesterday", range: [subDays(new Date(), 1), subDays(new Date(), 1)] },
					{ label: "Last 7 Days", range: [subDays(new Date(), 7), new Date()] },
					{ label: "Last 30 Days", range: [subDays(new Date(), 30), new Date()] },
					{ label: "This month", range: [startOfMonth(new Date()), endOfMonth(new Date())] },
					{ label: "Last month", range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
					{ label: "This year", range: [startOfYear(new Date()), endOfYear(new Date())] },
				],

				//ag-grid
				defaultColDef: null,
				columnDefs: null,
				rowData: null,
				gridApi: null,
				columnApi: null,
				api: null,
			};
		},
		methods: {
			rangeFormat(range) {
				return `${format(range[0], "PPPP")} - ${format(range[1], "PPPP")}`;
			},
			async search() {
				let body = {
					startTime: this.dateRange[0].toISOString(),
					endTime: this.dateRange[1].toISOString(),
				};

				let searchRes = await ApiService.postData("Violation/CameraAnalysis", body, {});
				if (searchRes.status == 200) {
					this.events = searchRes.data.detectedEvents;
					this.refreshTable(this.events);
					this.api.sizeColumnsToFit();
				}
			},
			onGridReady(params) {
				this.api = params.api;
				this.gridApi = params.api;
				this.columnApi = params.columnApi;
			},
			gridSizeChanged() {
				this.api.sizeColumnsToFit();
			},
			async refreshTable(data) {
				this.defaultColDef = {
					resizable: true,
					sortable: true,
					filter: true,
					floatingFilter: true,
					suppressMenu: true,
					filterParams: {
						buttons: ["apply", "reset"],
						closeOnApply: true,
						suppressAndOrCondition: true,
					},
				};

				this.columnDefs = [
					{ field: "type", headerName: "Event", filter: true, valueFormatter: this.eventNameFormatter },
					{ field: "time", headerName: "Date/Time", filter: true, valueFormatter: this.dateTimeFormatter },
				];
				this.rowData = data;
			},
			dateTimeFormatter(params) {
				if (params.value) {
					return format(parseISO(params.value), "yyyy-MM-dd HH:mm");
				} else {
					return null;
				}
			},
			eventNameFormatter(params) {
				if (params.value) {
					return EventType[params.value];
				} else {
					return null;
				}
			},
			createCustomCsv() {
				const csvString = [["Event", "Date/Time"], ...this.rowData.map((item) => [EventType[item.type], format(new Date(item.time), "P p")])].map((e) => e.join(",")).join("\n");
console.log(csvString)
				return csvString;
			},
			downloadCSVFile(filename) {
				let csv = this.createCustomCsv();

				var csv_file, download_link;

				csv_file = new Blob([csv], { type: "text/csv" });

				download_link = document.createElement("a");

				download_link.download = filename;

				download_link.href = window.URL.createObjectURL(csv_file);

				download_link.style.display = "none";

				document.body.appendChild(download_link);

				download_link.click();
			},
		},
		async mounted() {
			await this.search(this.dateRange[0], this.dateRange[1]);
			this.api.sizeColumnsToFit();
		},
		setup() {
			setCurrentPageTitle("Hangar Report");
		},
	});
</script>

<style lang="scss">
	@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
	@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>