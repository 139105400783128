<template>
  <div class="timeline">
    <div :class="`container ${ i % 2 === 0 ? 'left' : 'right'}`" v-for="(obj, i) in timelineObject" :key="i">
      <div class="content">
        <h2 :title="obj.date">{{formatDay(obj.date, obj.data[0].time)}}</h2>
        <div>
          <p v-for="(d,j) in obj.data" :key="j">
            <span v-if="i % 2 === 0">
              <i v-if="d.type === 1" class="fa fa-plane-arrival"></i>
              <i v-if="d.type === 2" class="fa fa-plane-departure"></i>
              <i v-if="d.type === 4" class="fa fa-xmarks-lines" style="rotate: 90deg"></i>
              <i v-if="d.type === 3" class="fa fa-xmarks-lines"></i>
            </span>
            {{ formatEventType(d.type) }}
            <span v-if="i % 2 !== 0">
              <i v-if="d.type === 1" class="fa fa-plane-arrival"></i>
              <i v-if="d.type === 2" class="fa fa-plane-departure"></i>
              <i v-if="d.type === 4" class="fa fa-xmarks-lines" style="rotate: 90deg"></i>
              <i v-if="d.type === 3" class="fa fa-xmarks-lines"></i>
            </span>
            <br>
            <b>{{new Date(d.time).toLocaleTimeString()}}</b>
          </p>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
const EventType = {
  1: "Aircraft Enters",
  2: "Aircraft Exits",
  3: "Fences Up",
  4: "Fences Down",
};
import { defineComponent } from "vue";
import {format, isToday, isYesterday} from "date-fns"
export default defineComponent({
  name: "Timeline",
  components: {},
  props: {
    timelineObject: [Object],
  },
  data: function () {
    return {
    };
  },
  methods: {
    formatDay(day, date){
      const now = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);           
      if(format(now, "y-M-d") === day){
          return "Today";
      } else if (format(yesterday, "y-M-d") === day) {        
          return "Yesterday";
      }
      else{
        var localdate = new Date(date);
        let year = Number(format(localdate, "y"));
        let thisYear = Number(now.getFullYear());
        if(year != thisYear){
          return format(localdate, "y LLL d")
        }else {
          return format(localdate, "LLL d")
        }
      }
    },
    formatEventType(id){
      return EventType[id] || "Unknown Event";
    },

  },
  async mounted() {
    console.log(this.timelineObject)
  },
});
</script>

<style lang="scss" scoped>

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 16px;
  margin-bottom: 10px;
  max-height: 450px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 49%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: white;
  border: 4px solid #FF9F55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: -25%;
}

/* Place the container to the right */
.right {
  left: 25%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -20px;
}

/* The actual content */
.content {
  padding: 0;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 20px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
.left {
  .content{
    text-align: right;
  }
}
.right {
  .content{
    text-align: left;
  }
}
</style>