
import ApiService from "@/core/services/ApiService";
import { Mutations} from "@/store/enums/StoreEnums";

export class UserContext {
    route;
    store;
    /**
     *
     */
    constructor(route, store) {
        this.route = route;
        this.store = store;

    }

    async buildCurrentUser(auth0){
        const currentUser =  await (await ApiService.get("user/current")).data;


        let locationGuid : string | null = null
        //if no location is specified in the URL
      let redirectLinkContent : any  = null;
      if (this.route.redirectedFrom){
        redirectLinkContent = this.route.redirectedFrom.query;

      }
      if(redirectLinkContent && redirectLinkContent.c){
        locationGuid = redirectLinkContent.c;
      } else if (this.route.query.c){
          locationGuid = this.route.query.c.toString();
        } else {
          locationGuid = localStorage.getItem("LocationGuid"); //what if location is now found? i'd want to erase this
          if (locationGuid !== null){
            if (currentUser.locations.filter(c => {return c.locationGuid === locationGuid}).length === 0){
              locationGuid = null;
            }
          }
          if (locationGuid === null){
            // take the prefereed location
            console.log("Prefs " + currentUser.preferences)
            if (currentUser.preferences){
                const prefs = JSON.parse(currentUser.preferences)
              if (prefs.defaultLocation){
                locationGuid = prefs.defaultLocation
              }
            }
          }
          // take the first location
        if (locationGuid === null && currentUser.locations[0]){
            locationGuid = currentUser.locations[0].locationGuid;
          }
        }

        if (currentUser.locations.length === 0)
          throw "user has 0 locations";

        const location = currentUser.locations.filter(c => {return c.locationGuid === locationGuid})[0]
        currentUser.currentLocation = location;
        // console.log("LOCATION: ", location);

      document.title = "overwatch: " + location.name;
        ApiService.setLocationHeader(location.locationGuid);
        //currentUser.otherUsers = await (await ApiService.get("user")).data;



        this.store.commit(Mutations.SET_USER, currentUser);
      }

}
