<template>
	<div class="row" v-if="params.isReport">
		<div @click="btnClickedHandler()" class="btn btn-icon btn-bg-light btn-active-color-primary col btn-sm" :title="params.isFeeds ? 'Watch' : 'Watch incident'">
			<InlineSvg src="\media\icons\duotone\Devices\TV1.svg"/>
		</div>
	</div>
	<div class="row" v-if="!params.isReport">
		<div v-if="params.value && params.value.isActive" @click="btnClickedHandler()" class="btn btn-icon btn-bg-light btn-active-color-primary col btn-sm" :title="params.isFeeds ? 'Watch' : 'Watch incident'">
			<InlineSvg src="\media\icons\duotone\Devices\TV1.svg"/>
		</div>
	</div>
</template>

<script>
	import { defineComponent, ref } from "vue";
	import InlineSvg from "vue-inline-svg";

	export default defineComponent({
		name: "BtnCellRenderer",
		components: { InlineSvg },
		props: {},
		data() {
			return {};
		},
		methods: {
			btnClickedHandler() {
				console.log(this.params.value);
				this.params.clicked(this.params.value);
			},
		},
	});
</script>

<style scoped>
	.margin-lr {
		margin: 0px 13px 0px 13px;
		/* margin-top: 12.5px; */
	}
	.margin-r {
		margin: 0px 13px 0px 0px;
		/* margin-top: 12.5px; */
	}
	i {
		font-size: 15px;
		cursor: pointer;
	}
	div {
		height: 21px !important;
	}
</style>
