<template>
  <canvas id="myCanvas"  style="border:1px solid #d3d3d3; width: 100%; height: 100%;"></canvas>

  <button v-for="opt in options" :key="opt" v-show="opt.Type === 'forklift'"  @click="drawRect(opt.BoundingBox.X, opt.BoundingBox.Y, opt.BoundingBox.Width, opt.BoundingBox.Height, 'myCanvas')">
    {{ opt.BoundingBox.X, opt.BoundingBox.Y, opt.BoundingBox.Width, opt.BoundingBox.Height }}</button>
</template>

<script>

import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "CanvasDraw",
  components: {},
  props: {
  },
  data: function () {
    return {
      blobImage: null,
      options: [
        {
          "Id": "forklift 1",
          "Type": "forklift",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 110,
              "Y": 274
            },
            "Size": {
              "IsEmpty": false,
              "Width": 117,
              "Height": 198
            },
            "X": 110,
            "Y": 274,
            "Width": 117,
            "Height": 198,
            "Left": 110,
            "Top": 274,
            "Right": 227,
            "Bottom": 472,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": 0,
          "Number": 1
        },
        {
          "Id": "gate 2",
          "Type": "gate",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 450,
              "Y": 376
            },
            "Size": {
              "IsEmpty": false,
              "Width": 115,
              "Height": 220
            },
            "X": 450,
            "Y": 376,
            "Width": 115,
            "Height": 220,
            "Left": 450,
            "Top": 376,
            "Right": 565,
            "Bottom": 596,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": null,
          "Number": 2
        },
        {
          "Id": "forklift 3",
          "Type": "forklift",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 1148,
              "Y": 568
            },
            "Size": {
              "IsEmpty": false,
              "Width": 131,
              "Height": 151
            },
            "X": 1148,
            "Y": 568,
            "Width": 131,
            "Height": 151,
            "Left": 1148,
            "Top": 568,
            "Right": 1279,
            "Bottom": 719,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": 0.0410048422774219,
          "Number": 3
        },
        {
          "Id": "gate 1",
          "Type": "gate",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 550,
              "Y": 276
            },
            "Size": {
              "IsEmpty": false,
              "Width": 90,
              "Height": 149
            },
            "X": 550,
            "Y": 276,
            "Width": 90,
            "Height": 149,
            "Left": 550,
            "Top": 276,
            "Right": 640,
            "Bottom": 425,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": null,
          "Number": 1
        },
        {
          "Id": "gate 3",
          "Type": "gate",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 603,
              "Y": 221
            },
            "Size": {
              "IsEmpty": false,
              "Width": 42,
              "Height": 65
            },
            "X": 603,
            "Y": 221,
            "Width": 42,
            "Height": 65,
            "Left": 603,
            "Top": 221,
            "Right": 645,
            "Bottom": 286,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": null,
          "Number": 3
        },
        {
          "Id": "forklift 2",
          "Type": "forklift",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 614,
              "Y": 275
            },
            "Size": {
              "IsEmpty": false,
              "Width": 150,
              "Height": 248
            },
            "X": 614,
            "Y": 275,
            "Width": 150,
            "Height": 248,
            "Left": 614,
            "Top": 275,
            "Right": 764,
            "Bottom": 523,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": 0.08902412767579009,
          "Number": 2
        },
        {
          "Id": "airplane 1",
          "Type": "airplane",
          "BoundingBox": {
            "Location": {
              "IsEmpty": false,
              "X": 290,
              "Y": 16
            },
            "Size": {
              "IsEmpty": false,
              "Width": 316,
              "Height": 257
            },
            "X": 290,
            "Y": 16,
            "Width": 316,
            "Height": 257,
            "Left": 290,
            "Top": 16,
            "Right": 606,
            "Bottom": 273,
            "IsEmpty": false
          },
          "CenterPoint": {

          },
          "Source": "Datature",
          "SpeedInKmH": null,
          "Number": 1
        }
      ]
    };
  },
  methods: {
    beginDrawing(){
      this.options.forEach(drawing=>{
        if(drawing.Type === "forklift"){
          let boundingBox = drawing.BoundingBox;
          this.drawRect(boundingBox.X, boundingBox.Y, boundingBox.Width, boundingBox.Height, "myCanvas")
        }
      })
    },
    drawRect(x, y, w, h, id){
      console.log("drawing rec, ", x, y, w, h, id)
      let c = document.getElementById(id);
      let ctx = c.getContext("2d");
      ctx.beginPath();
      ctx.rect(x, y, w, h);
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 1;
      ctx.stroke();
    },

    async fetchImages() {
      let token = await ApiService.get("Stream/SAS");
      token = token.data.split("?")[1];
      let today = new Date();
      let week = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

      let payload = { cameraId: 9, startTime: week, endTime: today };
      let images;
      await ApiService.post("Violation/LatestImages", payload).then((response) => {
        if (response) {
          images = response.data;
          if(images.length === 0){
            this.blobImage = null;
            console.log("no images found")
            return;
          }
        }
      });
      if (images.length > 0) {
        let j = images.length -1;
        const imgURL = `${images[j].imageUrl}?${token}`;
        this.blobImage = { url: imgURL, time: new Date(images[j].fileTimestamp) };
        console.log(imgURL)
        this.drawImg(imgURL, "myCanvas")
      } else {
      }
    },

    drawImg(imgSrc, id){
      let c = document.getElementById(id);
      let ctx = c.getContext("2d");
      let img = new Image();
      img.src = imgSrc;
      setTimeout(()=>{
        console.log(img.width, img.height)
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, c.width, c.height);
        this.beginDrawing();
      }, 2000)


    },
  },
  async mounted() {
    await this.fetchImages();
  },
});
</script>

<style lang="scss" scoped>

</style>