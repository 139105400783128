<template>
	<div class="row">
		<div class="col-6">
			<p class="timeText">{{ blobImages[imageIndex].time.toString().split(" ")[0] }} {{ blobImages[imageIndex].time.toString().split(" ")[1] }} {{ blobImages[imageIndex].time.toString().split(" ")[2] }} {{ blobImages[imageIndex].time.toString().split(" ")[3] }} {{ blobImages[imageIndex].time.toString().split(" ")[4] }} local time</p>
		</div>
		<div class="col-6">
			<Datepicker v-if="datePickerActive" :disabled="playerIntervalId != null" v-model="indexedDate" :allowedDates="allowedDates" autoApply class="mb-5" @update:modelValue="changeImageDay()" />
		</div>
	</div>

	<div id="carousel_controls" class="carousel slide my-1" data-bs-touch="false" data-bs-interval="false" v-if="blobImages.length > 0">
		<div class="carousel-inner ml-3 mr-3">
			<div class="carousel-item active text-center">
				<div class="row d-flex align-items-center" v-if="false">
					<div class="col-3 mx-5" style="padding-right: 45px">
						<div class="col-10">
							<input type="range" class="form-range" id="customRange1" v-model="playerSpeedfps" :disabled="playerIntervalId != null" max="10" min="1" />
						</div>
						<div class="col-2">FPS: {{ playerSpeedfps }}</div>
					</div>
				</div>
        <span v-if="blobImages[imageIndex].videoUrl && blobImages[imageIndex].url">
          <button @click="switchToVideo = !switchToVideo" class="switch-video"><i class="fa fa-repeat"></i></button>
          <video :src="blobImages[imageIndex].videoUrl" v-if="blobImages[imageIndex].videoUrl && switchToVideo" class="  customImage" controls autoplay></video>
          <img class="d-block w-100 customImage" :src="blobImages[imageIndex].url" :alt="imageIndex" v-else/>
        </span>
        <span v-if="!blobImages[imageIndex].videoUrl && blobImages[imageIndex].url">
          <video :src="blobImages[imageIndex].url" v-if="blobImages[imageIndex].url.includes('mp4')" class="  customImage" controls autoplay></video>
          <img class="d-block w-100 customImage" :src="blobImages[imageIndex].url" :alt="imageIndex" v-else/>
        </span>
			</div>
		</div>

		<span class="carousel-control-prev leftArrow" @click="previous" v-if="playerIntervalId == null && imageIndex > 0">&lsaquo;</span>
		<span class="carousel-control-next rightArrow" @click="next" v-if="playerIntervalId == null && imageIndex < blobImages.length - 1">&rsaquo;</span>
	</div>
	<div class="row" v-if="false">
		<div class="col text-center">
			<button class="btn btn-success m-5" v-if="playerIntervalId == null" @click="play">&#9658;</button>
			<button class="btn btn-danger m-5" v-if="playerIntervalId != null" @click="closePlayer"><i class="fa fa-stop"></i></button>
		</div>
	</div>
	<div class="row" v-if="blobImages && blobImages.length > 1">
		<div class="text-center">
			<input type="range" style="width: 50% !important" class="form-range" v-model="imageIndex" :disabled="playerIntervalId != null" step="1" :max="blobImages.length - 1" min="0" />
<!--      <datalist id="tickmarks">-->
<!--        <option v-for="step in blobImages" :value="step" label="0"></option>-->
<!--      </datalist>-->
			<output style="display: block !important"> {{ Number(imageIndex)+1 }}/{{ blobImages.length }} </output>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			blobImages: { type: Array, required: true },
			allowedDates: { type: Array, required: false },
			datePickerActive: { type: Boolean, required: true },
			startFromEnd: { type: Boolean, required: true },
		},
		data() {
			return {
        switchToVideo: false,
				imageIndex: 0,
				playerIntervalId: null,
				playerSpeedfps: 1,
				indexedDate: null,
			};
		},
		mounted() {
      console.log(this.blobImages)
			if (this.allowedDates.length > 0) {
				if (this.startFromEnd) {
					this.indexedDate = new Date(this.allowedDates[this.allowedDates.length - 1]);
					this.imageIndex = this.blobImages.length - 1;
				} else {
					this.indexedDate = new Date(this.allowedDates[0]);
					this.imageIndex = 0;
				}
			}
		},
		methods: {
			changeImageDay() {
				this.indexedDate = new Date(this.indexedDate);
				let day = this.indexedDate.toString().split(" ");
				let dateMade = `${day[2]} ${day[1]} ${day[3]}`;
				let nearestImageIndex = this.getNearestDateWithTime();
				console.log(nearestImageIndex);
				if (nearestImageIndex < this.blobImages.length && nearestImageIndex >= 0) {
					this.imageIndex = this.getNearestDateWithTime();
				} else {
					this.imageIndex = this.blobImagesDays.find((x) => x.day == dateMade).index;
				}
			},
			getNearestDateWithTime() {
				let differences = [];
				for (let i = 0; i < this.blobImages.length; i++) {
					let time = this.blobImages[i].time;
					let diff = time - this.indexedDate;
					differences.push(Math.abs(diff));
				}
				return differences.indexOf(Math.min(...differences));
			},
			changeSpeed(event) {
				this.playerSpeedfps = event.target.value / 10;
			},
			closePlayer() {
				clearInterval(this.playerIntervalId);
				this.playerIntervalId = null;
			},
			previous() {
				if (this.imageIndex == 0) {
					return;
				}
				this.imageIndex--;
			},
			next() {
				if (this.imageIndex == this.blobImages.length) {
					return;
				}
				this.imageIndex++;
			},
			play() {
				let self = this;

				if (this.blobImages.length > 0) {
					this.imageIndex = 0;
					this.playerIntervalId = setInterval(() => {
						self.imageIndex++;
						if (self.imageIndex == this.blobImages.length) {
							self.imageIndex = 0;
						}
					}, 1000 / this.playerSpeedfps);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.leftArrow {
		margin-left: -7%;
		color: black !important;
		font-size: 120px;
		cursor: pointer;
		user-select: none;
	}

	.rightArrow {
		margin-right: -7%;
		color: black !important;
		font-size: 120px;
		cursor: pointer;
		user-select: none;
	}
  .switch-video{
    position: absolute;
    right: 0;
    background: none;
    border: none;
    i{
      font-size: 25px;
    }
  }
</style>